import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { Storage } from "aws-amplify";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import BookingAPI from "../../api/booking";
import DesignerAPI from "../../api/designer";
import {
  AnonymousPhoto,
  AnonymousPhotoCategory,
  Booking,
  CreateAnonymousPhotoMutationVariables,
  CreateRegularCustomerNoteInput,
  Designer,
  NestedPhoto,
} from "../../graphql/API";
import RegularCustomerNoteAPI from "../../api/regular-customer-note";
import GA from "../../api/ga";
import PageHeader from "../Shared/PageHeader";
import styles from "./index.module.scss";
import DesignerIntroduction from "../Shared/DesignerIntroduction";
import { createAnonymousPhoto } from "../../graphql/mutations";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import AnonymousPhotoAPI from "../../api/anonymous-photo";
import DeleteIcon from "../SubmitSelfDiagnosis/Shared/StepOne/DeleteIcon";
import ProductV2 from "../Shared/ProductV2";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  Typography,
} from "@mui/material";
import QuestionTitle from "../SubmitSelfDiagnosis/Shared/QuestionTitle";
import { useSnackbar } from "notistack";

export default function SubmitRegularCustomerNote() {
  let { id } = useParams();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [booking, setBooking] = useState<Booking>();
  const [designer, setDesigner] = useState<Designer>();

  const defaultInput: CreateRegularCustomerNoteInput = {
    bookingId: "",
    shopId: "",
    customerId: "",
    customerName: "",
    levelOfStyleChange: "",
    modelVersion: 1,
  };

  const [input, setInput] =
    useState<CreateRegularCustomerNoteInput>(defaultInput);
  const [sending, setSending] = useState<boolean>(false);

  const bookingAPI = new BookingAPI();
  const designerAPI = new DesignerAPI();
  const regularCustomerNoteAPI = new RegularCustomerNoteAPI();

  const [uploading, setUploading] = useState(false);
  const [photos, setPhotos] = useState<AnonymousPhoto[]>([]);

  const anonymousPhotoAPI = new AnonymousPhotoAPI();

  async function fetchPhotos() {
    const bookingId = id ?? "";
    const photos = [];

    const items = await anonymousPhotoAPI.listByBookingId(bookingId);

    for (let i = 0; i < items.length; i += 1) {
      const item = {
        ...items[i],
      };

      const signedURL = await Storage.get(item.s3Key);

      item.url = signedURL;

      photos.push(item);
    }

    setPhotos(photos);
  }

  async function onFileInputChange(event: any) {
    setUploading(true);

    const targetLength = event?.target?.files.length ?? 0;

    if (photos.length + targetLength > 3) {
      toast.warn("사진은 최대 3장까지만 등록 가능합니다.");
      setUploading(false);
      return;
    }

    try {
      let files = [];
      for (var i = 0; i < targetLength; i++) {
        files.push(event.target.files.item(i));
      }
      await Promise.all(
        files.map((f, index) =>
          anonymousPhotoAPI.uploadFile(
            f,
            {
              displayOrder: photos.length + index,
              bookingId: booking?.id,
              requestId: undefined,
            },
            fetchPhotos
          )
        )
      );
    } catch (error: any) {
      toast.warn(error.message);
    } finally {
      setUploading(false);
    }
  }

  async function onDeletePhotoClick(photo: AnonymousPhoto) {
    await anonymousPhotoAPI.delete(photo.id, photo._version);

    fetchPhotos();
  }

  useEffect(() => {
    if (id) {
      initialize(id);
    }
  }, [id]);

  async function initialize(id: string) {
    const booking = await fetchBooking(id);

    if (!booking) {
      return;
    }

    const { id: bookingId, shopId, customerId, customer, designerId } = booking;

    setInput({
      ...input,
      bookingId,
      shopId,
      customerId,
      customerName: customer?.name ?? "",
    });

    if (designerId) {
      const designer = await designerAPI.get(designerId);

      setDesigner(designer);
    }
  }

  async function fetchBooking(id: string) {
    const booking = await bookingAPI.get(id);

    if (!booking) {
      toast.error("예약 정보를 찾을 수 없습니다.");
      return;
    }

    setBooking(booking);

    return booking;
  }

  async function onSendClick() {
    if (!booking) {
      return;
    }

    const note = await regularCustomerNoteAPI.get(booking.id);

    if (note) {
      toast.info("이미 제출하셨습니다.");
      return;
    }

    const { levelOfStyleChange } = input;

    if (!levelOfStyleChange) {
      toast.info("스타일 변화 정도를 선택해주세요.");
      return;
    }

    if (!confirm("이대로 전송할까요?")) {
      return;
    }

    setSending(true);

    try {
      const hasPhotos = photos.length > 0;

      const nestedPhotos: NestedPhoto[] = photos.map((photo) => {
        return {
          s3Key: photo.s3Key,
          displayOrder: photo.displayOrder,
        } as any;
      });

      const result = await regularCustomerNoteAPI.create({
        ...input,
        hasPhotos,
        photos: nestedPhotos,
      });

      if (result) {
        snackbar.enqueueSnackbar("사전 질문지 입력을 완료했습니다.", {
          variant: "success",
        });

        GA.event("사전 질문지", "사전 질문지 등록");
      }
    } finally {
      setSending(false);

      setTimeout(() => {
        fetchBooking(booking.id);
      }, 2000);

      setTimeout(() => {
        fetchBooking(booking.id);
      }, 3000);
    }
  }

  if (!booking) {
    return <>데이터를 확인하고 있습니다...</>;
  }

  if (booking.hasRegularCustomerNote) {
    return (
      <Box
        sx={{
          padding: 2,
        }}
      >
        <PageHeader title={"사전 질문지"} />
        <Box>
          <Typography variant="body1" gutterBottom>
            {booking.customer?.name} 고객님.
            <br />
            사전 질문지를 작성해 주셔서
            <br />
            진심으로 감사합니다. 🙇🏻
          </Typography>
        </Box>
        {designer && (designer.instagramUrl || designer?.youtubeUrl) && (
          <DesignerIntroduction designer={designer} />
        )}
        <ProductV2 />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <PageHeader title={"사전 질문지"} />
      <Box>
        <Typography variant="body1" gutterBottom>
          {booking.customer?.name} 고객님 안녕하세요!
          <br />
          꾸준히 예약해 주셔서 진심으로 감사합니다. 🙇🏻
        </Typography>
        <Typography variant="body2" gutterBottom>
          담당 디자이너가 서비스를 준비하는 데 참고할 수 있도록
          <br />
          사전 질문지를 작성해 주세요!
        </Typography>

        <Box sx={{ marginTop: 6 }}>
          <QuestionTitle title={"이번에 스타일 변화를 원하시나요?"} />
          <Box sx={{ marginTop: 2 }}>
            {["🙅🏻 최근 스타일 유지", "🙆🏻 약간의 변화", "✨ 스타일 변신"].map(
              (keyword) => {
                return (
                  <Button
                    key={keyword}
                    variant={
                      keyword === input.levelOfStyleChange
                        ? "contained"
                        : "outlined"
                    }
                    sx={{ marginInlineEnd: 1, marginBottom: 1 }}
                    color="info"
                    onClick={() => {
                      setInput({
                        ...input,
                        levelOfStyleChange: keyword,
                      });
                    }}
                  >
                    {keyword}
                  </Button>
                );
              }
            )}
          </Box>
        </Box>
        <Box sx={{ marginTop: 6 }}>
          <QuestionTitle
            title={"서비스 이후 일정이 있으신가요? (for 스타일링)"}
          />
          <Box sx={{ marginTop: 2 }}>
            {[
              "노코멘트",
              "친구/지인 모임",
              "가족 모임",
              "화려한 파티/행사",
              "격식 있는 자리",
              "데이트",
              "혼자만의 시간",
            ].map((keyword) => {
              return (
                <Button
                  key={keyword}
                  sx={{ marginInlineEnd: 1, marginBottom: 1 }}
                  variant={
                    keyword === input.nextSchedule ? "contained" : "outlined"
                  }
                  color="info"
                  onClick={() => {
                    setInput({
                      ...input,
                      nextSchedule: keyword,
                    });
                  }}
                >
                  {keyword}
                </Button>
              );
            })}
          </Box>
        </Box>
        <Box sx={{ marginTop: 6 }}>
          <QuestionTitle
            title={
              "지난번 방문 이후 시간이 지나면서 아쉬웠던 점 또는 오늘 궁금하신 점이 있나요?"
            }
            textInput={true}
          />
          <Box sx={{ marginTop: 2 }}>
            <FormControl fullWidth variant="standard">
              <Input
                type="text"
                autoComplete="off"
                onChange={(event) => {
                  setInput({ ...input, comment: event.target.value });
                }}
                multiline
                maxRows={4}
                placeholder="선택 입력"
              />
              <FormHelperText id="standard-weight-helper-text">
                담당 디자이너에게 전달하고 싶은 메시지를 자유롭게 입력해 주세요.
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ marginTop: 6 }}>
          <QuestionTitle
            title={"원하는 스타일이 있으시면 사진을 첨부해 주세요."}
            textInput={true}
          />
          <Box sx={{ marginTop: 2 }}>
            <div className={styles.RegularNoteBox}>
              {photos.length == 0 && (
                <Row className={styles.FirstPictureBox}>
                  <Col>
                    <Button
                      startIcon={<PhotoCameraIcon />}
                      variant="outlined"
                      color="info"
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById("add-image-file-input")
                          ?.click();
                      }}
                      disabled={uploading}
                    >
                      사진 첨부
                    </Button>
                    <input
                      id="add-image-file-input"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={onFileInputChange}
                      style={{ display: "none" }}
                    />
                  </Col>
                </Row>
              )}
              {photos.length > 0 && (
                <Row className={styles.PictureListBox}>
                  <>
                    {photos.map((photo) => {
                      return (
                        <Col key={photo.id} className={styles.PictureBox}>
                          <img src={(photo as any).url}></img>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              onDeletePhotoClick(photo);
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </Col>
                      );
                    })}
                    {photos.length < 3 && (
                      <div className={styles.PictureBox}>
                        <button
                          className={styles.PictureAddButton}
                          onClick={(e) => {
                            e.preventDefault();
                            document
                              .getElementById("add-image-file-input")
                              ?.click();
                          }}
                          disabled={uploading}
                        >
                          <img
                            src="/static/images/icon/plus-icon.png"
                            alt={"더하기 아이콘"}
                          />
                        </button>
                        <input
                          id="add-image-file-input"
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={onFileInputChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </>
                </Row>
              )}
            </div>
          </Box>
        </Box>
        <Box sx={{ marginTop: 6 }}>
          <Button
            sx={{ width: "150px" }}
            variant="contained"
            size="large"
            color="primary"
            disabled={sending}
            onClick={onSendClick}
          >
            {sending ? "제출중... 🤾🏻" : "제출하기"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
