import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  RegularCustomerQuestionnaireContent,
  CreateRegularCustomerQuestionnaireContentInput,
  CreateRegularCustomerQuestionnaireContentMutationVariables,
  UpdateRegularCustomerQuestionnaireContentInput,
} from "../../graphql/API";
import {
  createRegularCustomerQuestionnaireContent,
  updateRegularCustomerQuestionnaireContent,
} from "../../graphql/mutations";
import { getRegularCustomerQuestionnaireContent } from "../../graphql/queries";

export default class RegularCustomerQuestionnaireContentAPI {
  async get(id: string): Promise<RegularCustomerQuestionnaireContent> {
    const response: any = await API.graphql(
      graphqlOperation(getRegularCustomerQuestionnaireContent, {
        id,
      })
    );

    return response.data.getRegularCustomerQuestionnaireContent;
  }

  async create(
    input: CreateRegularCustomerQuestionnaireContentInput
  ): Promise<RegularCustomerQuestionnaireContent> {
    const variables: CreateRegularCustomerQuestionnaireContentMutationVariables =
      {
        input: {
          ...input,
          date: dayjs().format("YYYY-MM-DD"),
          yearMonth: dayjs().format("YYYYMM"),
        },
      };

    const response: any = await API.graphql(
      graphqlOperation(createRegularCustomerQuestionnaireContent, variables)
    );

    return response.data.createRegularCustomerQuestionnaireContent;
  }

  async update(input: UpdateRegularCustomerQuestionnaireContentInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateRegularCustomerQuestionnaireContent, {
        input,
      })
    );
  }
}
