import { Box } from "@mui/material";
import styles from "./index.module.scss";

export default function CategorySubTitle({ title }: { title: string }) {
  return (
    <div className={styles.CategorySubTitle}>
      <Box sx={{ typography: "subtitle2" }}>{title}</Box>
    </div>
  );
}
