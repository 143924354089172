import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import App from "../../App";
import Home from "../../components/Home";
import SubmitSelfDiagnosis from "../../components/SubmitSelfDiagnosis";
import Solution from "../../components/Solution";
import SubmitFeedback from "../../components/SubmitFeedback";
import { useEffect } from "react";
import SubmitRegularCustomerNote from "../../components/SubmitRegularCustomerNote";
import SubmitSelfDiagnosisContent from "../../components/SubmitSelfDiagnosisContent";
import Caring from "../../components/Caring";
import SubmitRegularCustomerQuestionnaireContent from "../../components/SubmitRegularCustomerQuestionnaireContent";

export default function RouteList() {
  let location = useLocation();

  useEffect(() => {
    initializeGA();
  }, []);

  useEffect(() => {
    trackPageView();
  }, [location]);

  function initializeGA() {
    if (process.env.REACT_APP_STAGE !== "prod") {
      return;
    }

    const TRACKING_ID = "G-H81YVGVRXY";

    ReactGA.initialize(TRACKING_ID);
  }

  function trackPageView() {
    if (process.env.REACT_APP_STAGE !== "prod") {
      return;
    }

    ReactGA.set({ page: location.pathname });
    ReactGA.send("pageview");
  }

  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="solution/:id" element={<Solution />} />
        <Route
          path="submit-self-diagnosis/:id"
          element={<SubmitSelfDiagnosis />}
        />
        <Route
          path="submit-self-diagnosis-content/:id"
          element={<SubmitSelfDiagnosisContent />}
        />
        <Route path="caring/:id" element={<Caring />} />
        <Route path="submit-feedback/:id" element={<SubmitFeedback />} />
        <Route
          path="submit-regular-customer-note/:id"
          element={<SubmitRegularCustomerNote />}
        />
        <Route
          path="submit-self-regular-customer-note/:id"
          element={<SubmitRegularCustomerNote />}
        />
        <Route
          path="submit-regular-customer-questionnaire-content/:id"
          element={<SubmitRegularCustomerQuestionnaireContent />}
        />
      </Route>
    </Routes>
  );
}
