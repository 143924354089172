import { Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import styles from "./index.module.scss";

export default function SingleSelectionFromStringArray({
  constants,
  inputValue,
  inputFieldName,
  onClick,
  xs,
  translationTable,
}: {
  constants: string[];
  inputValue: string;
  inputFieldName: string;
  onClick: any;
  xs?: number;
  translationTable?: { [key: string]: string };
}) {
  return (
    <Row className={styles.ButtonBox}>
      {constants.map((item: any) => (
        <Col xs={xs ? xs : 6} key={item} className="d-grid">
          <Button
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            color="info"
            variant={inputValue === item ? "contained" : "outlined"}
            onClick={(event) => {
              onClick(item, inputValue, inputFieldName);
              event.currentTarget.blur();
            }}
          >
            {translationTable ? translationTable[item] : item}
          </Button>
        </Col>
      ))}
    </Row>
  );
}
