import { API, graphqlOperation, Storage } from "aws-amplify";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import {
  AnonymousPhoto,
  AnonymousPhotoCategory,
  CreateAnonymousPhotoMutationVariables,
  DeleteAnonymousPhotoMutationVariables,
} from "../../graphql/API";
import {
  createAnonymousPhoto,
  deleteAnonymousPhoto,
} from "../../graphql/mutations";
import {
  anonymousPhotoByBookingId,
  anonymousPhotoByRequestId,
  getAnonymousPhoto,
} from "../../graphql/queries";

export default class AnonymousPhotoAPI {
  async get(id: string): Promise<AnonymousPhoto> {
    const response: any = await API.graphql(
      graphqlOperation(getAnonymousPhoto, {
        id,
      })
    );

    return response.data.getAnonymousPhoto;
  }

  async delete(id: string, _version: number) {
    const variables: DeleteAnonymousPhotoMutationVariables = {
      input: {
        id,
        _version,
      },
    };
    return API.graphql(graphqlOperation(deleteAnonymousPhoto, variables));
  }

  async listByBookingId(bookingId: string) {
    const result: any = await API.graphql({
      query: anonymousPhotoByBookingId,
      variables: {
        bookingId,
        sortDirection: "ASC",
      },
    });

    return result.data.anonymousPhotoByBookingId.items.filter(
      (item: AnonymousPhoto) => !item._deleted
    );
  }

  async listByRequestId(requestId: string) {
    const result: any = await API.graphql({
      query: anonymousPhotoByRequestId,
      variables: {
        requestId,
        sortDirection: "ASC",
      },
    });

    return result.data.anonymousPhotoByRequestId.items.filter(
      (item: AnonymousPhoto) => !item._deleted
    );
  }

  async uploadFile(
    file: any,
    params: { displayOrder: number; bookingId?: string; requestId?: string },
    fetchPhotos: () => void
  ) {
    const { displayOrder, bookingId, requestId } = params;
    try {
      const fileNameSlices = file.name.split(".");

      const fileNameExtension = fileNameSlices[fileNameSlices.length - 1];

      const fileName =
        dayjs().format("YYYYMMDD") + "/" + uuid() + "." + fileNameExtension;

      const result = await Storage.put(fileName, file, {
        metadata: { bookingId: bookingId || "", requestId: requestId || "" },
      });

      const s3Key = result.key;

      const variables: CreateAnonymousPhotoMutationVariables = {
        input: {
          category: AnonymousPhotoCategory.STYLE_INTERESTED_IN,
          s3Key,
          bookingId,
          requestId,
          displayOrder,
        },
      };

      await API.graphql({
        query: createAnonymousPhoto,
        variables,
      });

      fetchPhotos();
    } catch (error: any) {
      toast.error(error.message);
    }
  }
}
