import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CaringAPI from "../../api/caring";
import { Caring } from "../../graphql/API";

function CaringItem({ caption, body }: { caption: string; body: string }) {
  return (
    <Box sx={{ m: 2 }}>
      <Typography
        sx={{ mt: 0.5 }}
        color="text.secondary"
        display="block"
        variant="caption"
      >
        {caption}
      </Typography>
      <Typography sx={{ mt: 0.5 }} gutterBottom variant="body2">
        {body}
      </Typography>
    </Box>
  );
}

export default function CaringComponent() {
  let { id } = useParams();

  const snackbar = useSnackbar();

  const caringAPI = new CaringAPI();

  const [caring, setCaring] = useState<Caring>();

  useEffect(() => {
    if (!id) {
      return;
    }

    initialize(id);
  }, [id]);

  async function initialize(id: string) {
    const caring = await caringAPI.get(id);

    if (!caring) {
      snackbar.enqueueSnackbar("정보를 찾을 수 없습니다.", {
        variant: "warning",
      });
      return;
    }

    setCaring(caring);
  }

  if (!caring) {
    return <>Loading...</>;
  }

  return (
    <>
      <Box sx={{ marginTop: 4, marginBottom: 1 }}>
        <img
          src="/images/icons/caring-card.png"
          style={{ width: 252, height: 208 }}
        />
      </Box>
      <Paper
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          margin: "0 auto",
          textAlign: "left",
          wordBreak: "keep-all",
          mt: 2,
        }}
      >
        <Box sx={{ padding: 1 }}>
          <Box sx={{ mt: 2, mb: 3, mx: 2 }}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h6" component="div">
                  {caring.name}님을 위한 케어링
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                ></Typography>
              </Grid>
            </Grid>
            <Typography color="text.secondary" variant="body2">
              {dayjs(caring.date.substring(0, 10)).format("YYYY년 MM월 DD일")}{" "}
              {caring.shopName} {caring.designerName}님이 보내드리는 케어링
              카드입니다.
            </Typography>
          </Box>
          <Divider variant="middle" />
          <CaringItem caption="오늘 시술 포인트" body={caring.pointOfToday} />
          {caring.aftercareTips && (
            <CaringItem caption="관리 방법" body={caring.aftercareTips} />
          )}
          {caring.stylingTips && (
            <CaringItem caption="스타일링 방법" body={caring.stylingTips} />
          )}
          {caring.precautions && (
            <CaringItem caption="주의사항" body={caring.precautions} />
          )}
          {caring.serviceRecommendation && (
            <CaringItem
              caption="추천 시술"
              body={caring.serviceRecommendation}
            />
          )}
          {caring.nextVisitDate && (
            <CaringItem
              caption="다음 방문 일자"
              body={dayjs(caring.nextVisitDate.substring(0, 10)).format(
                "YYYY년 MM월 DD일"
              )}
            />
          )}
        </Box>
      </Paper>
      <Box sx={{ mt: 2, mb: 1, textAlign: "center" }}>
        <Typography color="text.secondary" variant="caption">
          Presented by{" "}
          <a href="https://nanalog.onelink.me/R4Vw/k5nscjjn">
            모미 for 헤어디자이너
          </a>
        </Typography>
      </Box>
    </>
  );
}
