import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import RouteList from "./app/RouteList";

import { Provider } from "react-redux";
import { store } from "./app/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createTheme, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

const environment = process.env.REACT_APP_STAGE;

if (environment) {
  Sentry.init({
    dsn: "https://9235f74adb3446c08f824651cedda979@o1104725.ingest.sentry.io/6778467",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const theme = createTheme({
  typography: {
    fontFamily: "Pretendard, -apple-system, sans-serif",
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
        <Provider store={store}>
          <BrowserRouter>
            <RouteList />
          </BrowserRouter>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
