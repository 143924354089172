// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const BookingOrigin = {
  "NAVER": "NAVER",
  "MANUAL": "MANUAL"
};

const DiscountMode = {
  "AMOUNT": "AMOUNT",
  "RATE": "RATE"
};

const ServiceStatus = {
  "NOT_STARTED": "NOT_STARTED",
  "IN_PROGRESS": "IN_PROGRESS",
  "DONE": "DONE"
};

const PaymentStatus = {
  "NOT_PAID": "NOT_PAID",
  "PAID": "PAID"
};

const BookingStatus = {
  "CONFIRMED": "CONFIRMED",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const NaverPaymentMoment = {
  "PRE": "PRE",
  "POST": "POST"
};

const NaverPaymentMethod = {
  "CREDIT_CARD": "CREDIT_CARD",
  "POINT": "POINT",
  "BANK_TRANSFER": "BANK_TRANSFER"
};

const NaverPaymentProvider = {
  "NAVER_PAY": "NAVER_PAY"
};

const NaverPaymentStatus = {
  "REGISTERED": "REGISTERED",
  "REQUESTED": "REQUESTED",
  "DEPOSIT_WAITING": "DEPOSIT_WAITING",
  "CHARGED": "CHARGED",
  "PAID": "PAID",
  "REFUNDED": "REFUNDED",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const ConsultationGender = {
  "MALE": "MALE",
  "FEMALE": "FEMALE"
};

const ConsultationStatus = {
  "NOT_STARTED": "NOT_STARTED",
  "IN_PROGRESS": "IN_PROGRESS",
  "DONE": "DONE"
};

const ConsultationSelfDiagnosisStatus = {
  "NOT_SENT": "NOT_SENT",
  "SENT": "SENT",
  "IN_PROGRESS": "IN_PROGRESS",
  "DONE": "DONE"
};

const PrepaidPassRecordType = {
  "EARN": "EARN",
  "SPEND": "SPEND"
};

const { DiscountPreset, Designer, Consultation, Photo, Booking, Customer, Shop, ServiceOption, PrepaidPassPreset, PrepaidPassHistory, Brand, Product, MutationResponse, DiscountPresetSnapshot, BookingServiceOption, ProductSnapshot, BookingProductOption, NaverServiceOption, NaverPaymentStatusHistory, NaverPayment, NaverSnapshot, NestedCustomer, Solution, HairRecommendation, NestedConsultation, ServiceOptionItem, ServiceOptionCategory } = initSchema(schema);

export {
  DiscountPreset,
  Designer,
  Consultation,
  Photo,
  Booking,
  Customer,
  Shop,
  ServiceOption,
  PrepaidPassPreset,
  PrepaidPassHistory,
  Brand,
  Product,
  BookingOrigin,
  DiscountMode,
  ServiceStatus,
  PaymentStatus,
  BookingStatus,
  NaverPaymentMoment,
  NaverPaymentMethod,
  NaverPaymentProvider,
  NaverPaymentStatus,
  ConsultationGender,
  ConsultationStatus,
  ConsultationSelfDiagnosisStatus,
  PrepaidPassRecordType,
  MutationResponse,
  DiscountPresetSnapshot,
  BookingServiceOption,
  ProductSnapshot,
  BookingProductOption,
  NaverServiceOption,
  NaverPaymentStatusHistory,
  NaverPayment,
  NaverSnapshot,
  NestedCustomer,
  Solution,
  HairRecommendation,
  NestedConsultation,
  ServiceOptionItem,
  ServiceOptionCategory
};