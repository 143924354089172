const inflowChannel = [
  "인스타그램",
  "네이버 플레이스",
  "네이버 블로그",
  "유튜브",
  "기존 고객",
  "소개",
  "제휴사",
  "워크인",
  "기타",
];

const outfitStyles = [
  "캐주얼",
  "모던시크",
  "러블리",
  "스트릿",
  "트렌디",
  "보헤미안",
];

const concerns = [
  {
    name: "모량",
    items: ["숱많음", "숱적음", "볼륨 적음"],
  },
  {
    name: "모발",
    items: ["손상 모발", "얇고 가는 모발", "곱슬", "직모", "새치"],
  },
  {
    name: "두피",
    items: ["탈모", "두피 열감", "두피 가려움", "비듬 각질", "두피 트러블"],
  },
];

const productsForShampooing = ["샴푸", "린스", "트리트먼트/팩", "헤어마스크"];

const productsForStyling = [
  "에센스",
  "컬 크림",
  "스프레이",
  "왁스",
  "포마드",
  "사용안함",
];

const treatmentsInterestedIn = [
  {
    name: "시술",
    items: ["커트", "염색", "펌"],
  },
  {
    name: "케어",
    items: ["모발 클리닉", "두피 클리닉"],
  },
  {
    name: "스타일",
    items: ["드라이", "아이롱", "반업/업 스타일"],
  },
];

const frequencyOfVisits = [
  {
    name: "자주 방문",
    items: ["1주 1회", "2주 1회", "1개월 1회"],
  },
  {
    name: "보통",
    items: ["1개월 이상 1회", "2개월 미만 1회", "3개월 미만 1회"],
  },
  {
    name: "가끔",
    items: ["6개월 1회", "1년 1회", "1년 이상"],
  },
];

const neckLength = ["중간", "길다", "짧다"];

const neckThickness = ["중간", "굵다", "얇다"];

const shoulderWidth = ["중간", "넓다", "좁다"];

const shoulderShape = ["승모근 수평", "승모근 높음", "승모근 낮음"];

const hairColor = [
  {
    name: "버진헤어 컬러",
    items: [
      "흑색",
      "아주 어두운 갈색",
      "어두운 갈색",
      "갈색",
      "밝은 갈색",
      "어두운 황갈색",
      "황갈색",
      "밝은 황갈색",
      "금색",
    ],
  },
  // {
  //   name: "염색 컬러",
  //   items: [
  //     "자주색",
  //     "빨강색",
  //     "분홍색",
  //     "주황색",
  //     "금색",
  //     "회색",
  //     "파랑색",
  //     "녹색",
  //     "보라색",
  //   ],
  // },
  {
    name: "염색 컬러",
    items: ["자주색", "빨강색", "분홍색", "주황색", "금색"],
  },
  {
    name: "염색 컬러",
    items: ["회색", "파랑색", "녹색", "보라색"],
  },
];

const hairColorHash = {
  "버진헤어 컬러_흑색": "#06080B",
  "버진헤어 컬러_아주 어두운 갈색": "#282016",
  "버진헤어 컬러_어두운 갈색": "#41311B",
  "버진헤어 컬러_갈색": "#584225",
  "버진헤어 컬러_밝은 갈색": "#795B2E",
  "버진헤어 컬러_어두운 황갈색": "#A57D3E",
  "버진헤어 컬러_황갈색": "#BD954A",
  "버진헤어 컬러_밝은 황갈색": "#CAA35D",
  "버진헤어 컬러_금색": "#C9B083",
  "염색 컬러_자주색": "#673147",
  "염색 컬러_빨강색": "#A63133",
  "염색 컬러_분홍색": "#F68B9D",
  "염색 컬러_주황색": "#CF7B40",
  "염색 컬러_금색":
    "linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #C9B082",
  "염색 컬러_회색": "#808080",
  "염색 컬러_파랑색": "#4898D0",
  "염색 컬러_녹색": "#48995C",
  "염색 컬러_보라색": "#7063A4",
};

const recentDyeing = ["없음", "0~3개월", "3~6개월", "6~12개월", "12개월 이상"];

const recentPerm = ["없음", "0~3개월", "3~6개월", "6~12개월", "12개월 이상"];

const damageDegreeOfHair = ["보통", "건강", "손상", "극손상"];

const amountOfHair = ["보통", "많음", "적음"];

const curlDegreeOfHair = ["반곱슬", "곱슬", "직모"];

const thicknessOfHair = ["보통", "굵음", "얇음"];

enum FaceShapeName {
  OVAL = "OVAL",
  OBLONG = "OBLONG",
  ROUND = "ROUND",
  PEANUT = "PEANUT",
  HEART = "HEART",
  DIAMOND = "DIAMOND",
  SQUARE = "SQUARE",
  HEXAGON = "HEXAGON",
  TRAPEZIUM = "TRAPEZIUM",
  IN_TRAPEZIUM = "IN_TRAPEZIUM",
  V_TRIANGLE = "V_TRIANGLE",
}

type FaceShape = {
  name: FaceShapeName;
  forehead: string;
  cheekbone: string;
  jaw: string;
  size: string;
  imageFilePath: string;
  title: string;
  description: string;
};

const faceShape: FaceShape[] = [
  {
    name: FaceShapeName.OVAL,
    forehead: "보통",
    cheekbone: "보통",
    jaw: "보통",
    size: "보통",
    imageFilePath: "oval",
    title: "계란형",
    description:
      "가로와 세로의 비율이 3:4로 가장 이상적인 형태의 얼굴형입니다.",
  },
  {
    name: FaceShapeName.OBLONG,
    forehead: "넓다",
    cheekbone: "좁다",
    jaw: "길다",
    size: "길다",
    imageFilePath: "oblong",
    title: "긴형",
    description:
      "광대가 좁고 이마나 턱이 긴 편으로 가로에 비해 세로의 비율이 긴 형태의 얼굴형으로 도시적이고 세련된 이미지를 줄 수 있습니다.",
  },
  {
    name: FaceShapeName.ROUND,
    forehead: "보통/좁다",
    cheekbone: "넓다",
    jaw: "넓다",
    size: "넓다",
    imageFilePath: "round",
    title: "둥근형",
    description:
      "가로길이가 넓고 세로는 짧은 둥근 형은 부드럽고 귀여운 동안의 이미지를 줄 수 있습니다.",
  },
  {
    name: FaceShapeName.PEANUT,
    forehead: "넓다",
    cheekbone: "좁다",
    jaw: "넓다",
    size: "보통",
    imageFilePath: "peanut",
    title: "땅콩형",
    description:
      "광대가 좁은데 비해 턱이 넓은 전체적으로 갸름해보이는 얼굴형입니다. 얼굴이 다소 길어보일 수 있습니다.",
  },
  {
    name: FaceShapeName.HEART,
    forehead: "갈매기 모양",
    cheekbone: "돌출/넓다",
    jaw: "좁고 뾰족",
    size: "보통",
    imageFilePath: "heart",
    title: "하트형",
    description:
      "광대가 있고 턱이 뾰족한 형태에 M자 이마가 특징입니다. 이마 보완만 하면 동양인이 선호하는 v라인이 돋보일 수 있습니다.",
  },
  {
    name: FaceShapeName.DIAMOND,
    forehead: "뾰족",
    cheekbone: "옆광대 돌출",
    jaw: "뾰족",
    size: "보통",
    imageFilePath: "diamond",
    title: "다이아몬드형",
    description:
      "뾰족하고 좁은 이마와 턱의 형태로 광대 부분만 보완하면 대부분의 스타일을 소화하기 좋은 얼굴형입니다.",
  },
  {
    name: FaceShapeName.SQUARE,
    forehead: "각지고 넓다",
    cheekbone: "넓다",
    jaw: "각지고 넓다",
    size: "보통",
    imageFilePath: "square",
    title: "사각형",
    description:
      "턱이 각지고 넓은 형태로 서구적이고 지적인 매력이 돋보이는 얼굴형입니다.",
  },
  {
    name: FaceShapeName.HEXAGON,
    forehead: "좁으면서 넓다",
    cheekbone: "넓다",
    jaw: "각지고 턱 뾰족",
    size: "보통",
    imageFilePath: "hexagon",
    title: "육각형",
    description:
      "얼굴 전체에 각이 여러 개가 있는 형태로 서구적인 매력이 있으며, 개성있는 헤어스타일을 소화하기 좋은 얼굴형입니다.",
  },
  {
    name: FaceShapeName.TRAPEZIUM,
    forehead: "좁다",
    cheekbone: "넓다",
    jaw: "각지고 넓다",
    size: "보통",
    imageFilePath: "trapezium",
    title: "사다리꼴",
    description:
      "이마가 좁고 턱이 넓으면서 각진 형태로 강인한 매력이 돋보이는 얼굴형입니다.",
  },
  {
    name: FaceShapeName.IN_TRAPEZIUM,
    forehead: "각지고 넓다",
    cheekbone: "넓다",
    jaw: "넓다",
    size: "보통",
    imageFilePath: "in-trapezium",
    title: "역사다리꼴",
    description:
      "이마가 옆으로 넓고 턱이 각진 스타일로 이마 커버가 중요한 얼굴형입니다. 지적이고 강인한 매력이 있습니다.",
  },
  {
    name: FaceShapeName.V_TRIANGLE,
    forehead: "각지고 넓다",
    cheekbone: "좁다",
    jaw: "좁고 뾰족",
    size: "보통",
    imageFilePath: "v-triangle",
    title: "역삼각형",
    description:
      "이마가 옆으로 넓고 턱이 턱이 뾰족한 형태로 날렵하고 날씬해보이는 매력이 있는 얼굴형입니다.",
  },
];

enum FrontHeadShapeName {
  앞면_볼륨_뒷면_볼륨 = "앞면_볼륨_뒷면_볼륨",
  앞면_볼륨_뒷면_플랫 = "앞면_볼륨_뒷면_플랫",
  앞면_플랫_뒷면_볼륨 = "앞면_플랫_뒷면_볼륨",
  앞면_플랫_뒷면_플랫 = "앞면_플랫_뒷면_플랫",
}

type FrontHeadShape = {
  name: FrontHeadShapeName;
  front: string;
  back: string;
  imageFilePath: string;
  description: string;
};

const frontHeadShape: FrontHeadShape[] = [
  {
    name: FrontHeadShapeName.앞면_볼륨_뒷면_볼륨,
    front: "볼륨",
    back: "볼륨",
    imageFilePath: "front-vol-vol",
    description: "이마와 뒷통수가 볼록하여 따로 볼륨을 주지 않아도 됩니다.",
  },
  {
    name: FrontHeadShapeName.앞면_볼륨_뒷면_플랫,
    front: "볼륨",
    back: "플랫",
    imageFilePath: "front-vol-flat",
    description:
      "이마가 볼록하고 뒷통수가 평평하여 뒷 부분에 볼륨이 필요합니다.",
  },
  {
    name: FrontHeadShapeName.앞면_플랫_뒷면_볼륨,
    front: "플랫",
    back: "볼륨",
    imageFilePath: "front-flat-vol",
    description:
      "이마가 평평하고 뒷통수가 볼록하여 앞머리 쪽 볼륨이 필요합니다.",
  },
  {
    name: FrontHeadShapeName.앞면_플랫_뒷면_플랫,
    front: "플랫",
    back: "플랫",
    imageFilePath: "front-flat-flat",
    description: "이마와 뒷통수가 평평하여 앞뒤로 볼륨이 필요합니다.",
  },
];

enum SideHeadShapeName {
  옆면_볼륨_윗면_볼륨 = "옆면_볼륨_윗면_볼륨",
  옆면_플랫_윗면_볼륨 = "옆면_플랫_윗면_볼륨",
  옆면_볼륨_윗면_플랫 = "옆면_볼륨_윗면_플랫",
  옆면_플랫_윗면_플랫 = "옆면_플랫_윗면_플랫",
}

type SideHeadShape = {
  name: SideHeadShapeName;
  side: string;
  top: string;
  imageFilePath: string;
  description: string;
};

const sideHeadShape: SideHeadShape[] = [
  {
    name: SideHeadShapeName.옆면_볼륨_윗면_볼륨,
    side: "볼륨",
    top: "볼륨",
    imageFilePath: "side-vol-vol",
    description: "양 옆과 윗 부분이 볼록하여 볼륨을 주지 않아도 됩니다.",
  },
  {
    name: SideHeadShapeName.옆면_플랫_윗면_볼륨,
    side: "플랫",
    top: "볼륨",
    imageFilePath: "side-flat-vol",
    description:
      "윗 부분이 볼록하고 양 옆이 평평하여 옆통수 볼륨이 필요합니다.",
  },
  {
    name: SideHeadShapeName.옆면_볼륨_윗면_플랫,
    side: "볼륨",
    top: "플랫",
    imageFilePath: "side-vol-flat",
    description: "양 옆이 볼록하고 윗 부분이 평평하여 윗 볼륨이 필요합니다.",
  },
  {
    name: SideHeadShapeName.옆면_플랫_윗면_플랫,
    side: "플랫",
    top: "플랫",
    imageFilePath: "side-flat-flat",
    description: "양 옆과 윗 부분이 평평하여 전체적으로 볼륨이 필요합니다.",
  },
];

//#region Step 3 진단 솔루션
const bangs = {
  female: [
    {
      name: "",
      items: ["풀뱅", "소프트 풀뱅", "쳐피뱅", "사이드뱅"],
    },
    {
      name: "",
      items: ["이너뱅", "시스루뱅", "커튼뱅", "페이스 라인컷"],
    },
  ],
  male: [
    {
      name: "",
      items: ["댄디컷", "투블럭컷", "포마드 컷", "리젠트컷", "M커버"],
    },
    {
      name: "",
      items: ["풀뱅", "소프트 풀뱅", "쳐피뱅", "사이드뱅"],
    },
    {
      name: "",
      items: ["이너뱅", "시스루뱅"],
    },
  ],
};

const hairLength = {
  female: [
    {
      name: "",
      items: ["숏(크롭)", "숏(귀선)", "숏(턱선)", "커트단발"],
    },
    {
      name: "",
      items: ["보브(귀선)", "보브(턱선)", "미디움(목선)", "미디움(어깨선)"],
    },
    {
      name: "",
      items: [
        "롱미디움(쇄골선)",
        "롱(겨드랑이선)",
        "롱(가슴선)",
        "오버롱(가슴선 밑)",
      ],
    },
  ],
  male: [
    {
      name: "",
      items: ["숏(크롭)", "숏(귀선)", "숏(턱선)"],
    },
    {
      name: "",
      items: ["커트단발", "보브(턱선)"],
    },
    {
      name: "",
      items: ["미디움(목선)", "미디움(어깨선이상)"],
    },
  ],
};

const hairVolume = {
  female: [
    {
      name: "",
      items: [
        "헤어라인 볼륨",
        "탑부분 볼륨",
        "크라운 부분 볼륨",
        "템플 부분 볼륨",
      ],
    },
    {
      name: "",
      items: [
        "사이드 부분 볼륨",
        "두상의 백부분(뒷통수) 볼륨",
        "백사이드 볼륨업",
      ],
    },
    {
      name: "",
      items: [
        "탑부분 볼륨다운",
        "템포랄본 부분볼륨 다운",
        "사이드부분 볼륨다운",
        "네이프 볼륨다운",
      ],
    },
  ],
  male: [
    {
      name: "",
      items: [
        "헤어라인 볼륨",
        "탑부분 볼륨",
        "크라운 부분 볼륨",
        "템플 부분 볼륨",
      ],
    },
    {
      name: "",
      items: [
        "사이드 부분 볼륨",
        "두상의 백부분(뒷통수) 볼륨",
        "백사이드 볼륨업",
      ],
    },
    {
      name: "",
      items: [
        "탑부분 볼륨다운",
        "템포랄본 부분볼륨 다운",
        "사이드부분 볼륨다운",
        "네이프 볼륨다운",
      ],
    },
  ],
};

const haircut = {
  female: [
    {
      name: "",
      items: [
        "원랭스(라운드)",
        "원랭스(스퀘어)",
        "그라쥬에이션(A라인)",
        "그라쥬에이션(U라인)",
        "그라쥬에이션(bob)",
        "그라쥬에이션+레이어",
      ],
    },
    {
      name: "",
      items: [
        "레이어(라운드)",
        "레이어(스퀘어)",
        "레이어(콘케이브)",
        "원랭스+레이어(라운드)",
        "원랭스+레이어(스퀘어)",
        "원랭스+레이어(콘케이브)",
        "원랭스+그라쥬에이션",
        "원랭스+그라쥬에이션+레이어",
      ],
    },
    {
      name: "",
      items: ["디스커넥션", "앞머리컷", "페이스라인컷"],
    },
  ],
  male: [
    {
      name: "",
      items: ["크롭컷", "디스커넥션"],
    },
    {
      name: "",
      items: ["맨즈클래식컷", "앞머리컷"],
    },
    {
      name: "",
      items: ["투블럭컷", "M커버"],
    },
  ],
};

const hairColorMenu = [
  {
    name: "",
    items: ["레벨 업", "레벨 다운"],
  },
  {
    name: "",
    items: ["하이라이트", "로우라이트", "옴브레", "디자인 부분 염색"],
  },
  {
    name: "",
    items: ["뿌리염색", "새치뿌리염색", "새치염색", "새치 패션염색"],
  },
];

const hairColorType = [
  { name: "", items: ["레드", "핑크", "오렌지", "베이지"] },
  { name: "", items: ["블랙", "블루", "바이올렛"] },
  { name: "", items: ["카키", "블론드", "애쉬"] },
];

const hairColorTypeHash = {
  레드: "#A63133",
  블랙: "#000000",
  카키: "#78804D",
  핑크: "#EDACB1",
  블루: "#4898D0",
  블론드: "#BBAEA1",
  오렌지: "#CF7B40",
  바이올렛: "#7063A4",
  애쉬: "#C0BBB5",
  베이지: "#B5B2A7",
};

const hairFirm = {
  female: [
    {
      name: "",
      items: [
        "콜드 내추럴 웨이브(발롱)",
        "디지털 내추럴 웨이브(발롱)",
        "콜드 히피펌",
        "디지털 히피펌",
        "글램웨이브",
      ],
    },
    {
      name: "",
      items: ["콜드 C컬펌", "디지털 C컬펌", "콜드 CS컬펌", "디지털 CS컬펌"],
    },
    {
      name: "",
      items: [
        "바디펌",
        "태슬펌",
        "뿌리볼륨펌",
        "뿌리매직",
        "콜드 앞머리펌",
        "디지털 앞머리펌",
      ],
    },
  ],
  male: [
    {
      name: "",
      items: ["다운펌", "애즈펌", "쉐도우펌", "아이롱펌"],
    },
    {
      name: "",
      items: ["가르마펌", "베이비펌", "포마드펌", "디자인펌"],
    },
    {
      name: "",
      items: ["가일펌", "호일펌", "볼륨매직", "디지털펌"],
    },
  ],
};

const hairCare = [
  "두피스케일링",
  "모발트리트먼트(라이트)",
  "두피+모발케어",
  "두피케어",
  "모발트리트먼트(딥)",
];

const hairRecommendation = {
  bangs,
  hairLength,
  hairVolume,
  haircut,
  hairColorMenu,
  hairColorType,
  hairFirm,
  hairCare,
  hairColorTypeHash,
};

//#endregion

const singleValueFieldNames = [
  "inflowChannel",
  "frequencyOfVisits",
  "neckLength",
  "neckThickness",
  "shoulderWidth",
  "shoulderShape",
  "recentDyeing",
  "recentPerm",
  "damageDegreeOfHair",
  "amountOfHair",
  "thicknessOfHair",
  "curlDegreeOfHair",
  "hairColor",
];

const consultationConstants = {
  inflowChannel,
  outfitStyles,
  concerns,
  productsForShampooing,
  productsForStyling,
  treatmentsInterestedIn,
  frequencyOfVisits,
  faceShape,
  frontHeadShape,
  sideHeadShape,
  neckLength,
  neckThickness,
  shoulderWidth,
  shoulderShape,
  hairColor,
  recentDyeing,
  recentPerm,
  damageDegreeOfHair,
  amountOfHair,
  curlDegreeOfHair,
  thicknessOfHair,
  hairColorHash,
  hairRecommendation,
  singleValueFieldNames,
};

export default consultationConstants;
