import { API, graphqlOperation } from "aws-amplify";
import { Shop } from "../../graphql/API";

const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      createdAt
      updatedAt
      naverBusinessId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default class ShopAPI {
  async get(id: string): Promise<Shop> {
    const response: any = await API.graphql(
      graphqlOperation(getShop, {
        id,
      })
    );

    return response.data.getShop;
  }
}
