import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  SelfDiagnosis,
  CreateSelfDiagnosisInput,
  CreateSelfDiagnosisMutationVariables,
  UpdateSelfDiagnosisInput,
} from "../../graphql/API";
import {
  createSelfDiagnosis,
  updateSelfDiagnosis,
} from "../../graphql/mutations";
import { getSelfDiagnosis } from "../../graphql/queries";

export default class SelfDiagnosisAPI {
  async get(id: string): Promise<SelfDiagnosis> {
    const response: any = await API.graphql(
      graphqlOperation(getSelfDiagnosis, {
        id,
      })
    );

    return response.data.getSelfDiagnosis;
  }

  async create(input: CreateSelfDiagnosisInput): Promise<SelfDiagnosis> {
    const variables: CreateSelfDiagnosisMutationVariables = {
      input: {
        ...input,
        date: dayjs().format("YYYY-MM-DD"),
        yearMonth: dayjs().format("YYYYMM"),
      },
    };

    const response: any = await API.graphql(
      graphqlOperation(createSelfDiagnosis, variables)
    );

    return response.data.createSelfDiagnosis;
  }

  async update(input: UpdateSelfDiagnosisInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateSelfDiagnosis, {
        input,
      })
    );
  }
}
