import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  SelfDiagnosisRequest,
  CreateSelfDiagnosisRequestInput,
  CreateSelfDiagnosisRequestMutationVariables,
  UpdateSelfDiagnosisRequestInput,
} from "../../graphql/API";
import {
  createSelfDiagnosisRequest,
  updateSelfDiagnosisRequest,
} from "../../graphql/mutations";

const getSelfDiagnosisRequest = /* GraphQL */ `
  query GetSelfDiagnosisRequest($id: ID!) {
    getSelfDiagnosisRequest(id: $id) {
      id
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      sentAt
      writtenAt
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default class SelfDiagnosisRequestAPI {
  async get(id: string): Promise<SelfDiagnosisRequest> {
    const response: any = await API.graphql(
      graphqlOperation(getSelfDiagnosisRequest, {
        id,
      })
    );

    return response.data.getSelfDiagnosisRequest;
  }

  async create(
    input: CreateSelfDiagnosisRequestInput
  ): Promise<SelfDiagnosisRequest> {
    const variables: CreateSelfDiagnosisRequestMutationVariables = {
      input: {
        ...input,
        yearMonth: dayjs().format("YYYYMM"),
      },
    };

    const response: any = await API.graphql(
      graphqlOperation(createSelfDiagnosisRequest, variables)
    );

    return response.data.createSelfDiagnosisRequest;
  }

  async update(input: UpdateSelfDiagnosisRequestInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateSelfDiagnosisRequest, {
        input,
      })
    );
  }
}
