import React from "react";
import "./App.css";
import { Outlet } from "react-router-dom";

import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";
import ConsultationTermsModal from "./components/Shared/TermsModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Shared/Footer";

Amplify.configure(awsExports);

function App() {
  const hideFooter =
    location.href.indexOf("submit-self-diagnosis") > -1 ||
    location.href.indexOf("caring") > -1;

  return (
    <div className="App">
      <Outlet />
      {hideFooter ? null : <Footer />}
      <ConsultationTermsModal />
      <ToastContainer position="top-center" hideProgressBar={true} />
    </div>
  );
}

export default App;
