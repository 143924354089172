import { Col, Container, Row } from "react-bootstrap";
import Product from "../Shared/Product";
import ProductV2 from "../Shared/ProductV2";

export default function Home() {
  return (
    <Container>
      <Row>
        <Col style={{ marginTop: 16 }}>
          <img
            src="/images/icons/device-app-icon.png"
            alt="나나로그 로고"
            width={38}
            height={38}
          />
        </Col>
      </Row>
      <ProductV2 />
    </Container>
  );
}
