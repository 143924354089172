import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  RegularCustomerQuestionnaireRequest,
  CreateRegularCustomerQuestionnaireRequestInput,
  CreateRegularCustomerQuestionnaireRequestMutationVariables,
  UpdateRegularCustomerQuestionnaireRequestInput,
} from "../../graphql/API";
import {
  createRegularCustomerQuestionnaireRequest,
  updateRegularCustomerQuestionnaireRequest,
} from "../../graphql/mutations";

const getRegularCustomerQuestionnaireRequest = /* GraphQL */ `
  query GetRegularCustomerQuestionnaireRequest($id: ID!) {
    getRegularCustomerQuestionnaireRequest(id: $id) {
      id
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      sentAt
      writtenAt
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default class RegularCustomerQuestionnaireRequestAPI {
  async get(id: string): Promise<RegularCustomerQuestionnaireRequest> {
    const response: any = await API.graphql(
      graphqlOperation(getRegularCustomerQuestionnaireRequest, {
        id,
      })
    );

    return response.data.getRegularCustomerQuestionnaireRequest;
  }

  async create(
    input: CreateRegularCustomerQuestionnaireRequestInput
  ): Promise<RegularCustomerQuestionnaireRequest> {
    const variables: CreateRegularCustomerQuestionnaireRequestMutationVariables =
      {
        input: {
          ...input,
          yearMonth: dayjs().format("YYYYMM"),
        },
      };

    const response: any = await API.graphql(
      graphqlOperation(createRegularCustomerQuestionnaireRequest, variables)
    );

    return response.data.createRegularCustomerQuestionnaireRequest;
  }

  async update(input: UpdateRegularCustomerQuestionnaireRequestInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateRegularCustomerQuestionnaireRequest, {
        input,
      })
    );
  }
}
