import { API } from "aws-amplify";
import { Designer } from "../../graphql/API";
import { getDesigner } from "../../graphql/queries";

export default class DesignerAPI {
  async get(id: string): Promise<Designer> {
    const response: any = await API.graphql({
      query: getDesigner,
      variables: { id },
    });
    return response.data.getDesigner;
  }
}
