import { Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./index.module.scss";

type Product = {
  url: string;
  imageFilePath: string;
  title: string;
  description: string;
};

const products: Product[] = [
  {
    url: "https://www.monclos.com/b2b/?idx=175",
    imageFilePath: "/images/products/monclos/volume-shampoo.png",
    title: "몽클로스 볼륨 샴푸",
    description: "#약산성 #워터리제형 #수분 #손상모 #윤기 #탈모완화",
  },
  {
    url: "https://www.monclos.com/b2b/?idx=174",
    imageFilePath: "/images/products/monclos/scalf-shampoo.png",
    title: "몽클로스 스칼프 샴푸",
    description:
      "#약산성 #워터리제형 #두피유수분 #피지 #비듬 #가려움 #탈모완화",
  },
  {
    url: "https://www.monclos.com/b2b/?idx=173",
    imageFilePath: "/images/products/monclos/treatment.png",
    title: "몽클로스 실키 터치 트리트먼트",
    description: "#워시오프타입 #11가지펩타이드컴플렉스함유 #극손상모케어",
  },
  {
    url: "https://www.monclos.com/b2b/?idx=172",
    imageFilePath: "/images/products/monclos/essence.jpeg",
    title: "몽클로스 엔젤 실크 터치 에센스",
    description: "#식물유래성분 #그리놀 #영양 #보습 #윤기",
  },
  {
    url: "https://www.monclos.com/b2b/?idx=171",
    imageFilePath: "/images/products/monclos/volume-fixer.png",
    title: "몽클로스 헤어 볼륨 픽서",
    description: "#미스트타입 #픽서스프레이 #뿌리볼륨 #스타일완성",
  },
];

export default function ProductV2() {
  useEffect(() => {}, []);

  return (
    <>
      <Row>
        <Col>
          <div
            style={{
              height: 1,
              backgroundColor: "#e5e5e5",
              margin: "0 auto",
              marginTop: 15,
            }}
          ></div>
        </Col>
      </Row>
      <Row>
        <Col className={styles.Headline}>
          💁🏻‍♀️ 나나로그에서 추천하는 제품을 확인해 보세요.
        </Col>
      </Row>
      <Box sx={{ paddingBottom: 1 }}>
        <Alert severity="info" icon={false} sx={{ wordBreak: "keep-all" }}>
          알고계셨나요? 🤔
          <br />
          사람의 두피는 정도의 차이만 있을 뿐 모두 복합성입니다. 따라서 볼륨
          샴푸와 스칼프 샴푸 중 하나만 쓰시는 것이 아니라 두 가지를 번갈아
          사용하시되 두피 타입에 따라 좀 더 자주 쓰는 샴푸 비중을 맞춰주시면
          더욱 효과적입니다.
        </Alert>
      </Box>
      <Row>
        {products.map((product) => {
          return (
            <Col xs={6} sm={4} key={product.url} className={styles.ProductCol}>
              <a href={product.url} target="_blank">
                <img src={product.imageFilePath} />
                <div className={styles.ProductTitle}>{product.title}</div>
                <div className={styles.ProductDescription}>
                  {product.description}
                </div>
              </a>
            </Col>
          );
        })}
      </Row>
    </>
  );
}
