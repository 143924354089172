import { API, graphqlOperation } from "aws-amplify";
import { Consultation } from "../../graphql/API";
import { getConsultation } from "../../graphql/queries";

export default class ConsultationAPI {
  async get(id: string): Promise<Consultation> {
    const response: any = await API.graphql(
      graphqlOperation(getConsultation, {
        id,
      })
    );

    return response.data.getConsultation;
  }
}
