import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  Caring,
  CreateCaringInput,
  CreateCaringMutationVariables,
  UpdateCaringInput,
} from "../../graphql/API";
import { createCaring, updateCaring } from "../../graphql/mutations";

const getCaring = /* GraphQL */ `
  query GetCaring($id: ID!) {
    getCaring(id: $id) {
      id
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      timestamp
      pointOfToday
      aftercareTips
      stylingTips
      precautions
      serviceRecommendation
      nextVisitDate
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default class CaringAPI {
  async get(id: string): Promise<Caring> {
    const response: any = await API.graphql(
      graphqlOperation(getCaring, {
        id,
      })
    );

    return response.data.getCaring;
  }

  async create(input: CreateCaringInput): Promise<Caring> {
    const variables: CreateCaringMutationVariables = {
      input: {
        ...input,
        yearMonth: dayjs().format("YYYYMM"),
      },
    };

    const response: any = await API.graphql(
      graphqlOperation(createCaring, variables)
    );

    return response.data.createCaring;
  }

  async update(input: UpdateCaringInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateCaring, {
        input,
      })
    );
  }
}
