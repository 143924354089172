import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  SelfDiagnosisContent,
  CreateSelfDiagnosisContentInput,
  CreateSelfDiagnosisContentMutationVariables,
  UpdateSelfDiagnosisContentInput,
} from "../../graphql/API";
import {
  createSelfDiagnosisContent,
  updateSelfDiagnosisContent,
} from "../../graphql/mutations";
import { getSelfDiagnosisContent } from "../../graphql/queries";

export default class SelfDiagnosisContentAPI {
  async get(id: string): Promise<SelfDiagnosisContent> {
    const response: any = await API.graphql(
      graphqlOperation(getSelfDiagnosisContent, {
        id,
      })
    );

    return response.data.getSelfDiagnosisContent;
  }

  async create(
    input: CreateSelfDiagnosisContentInput
  ): Promise<SelfDiagnosisContent> {
    const variables: CreateSelfDiagnosisContentMutationVariables = {
      input: {
        ...input,
        yearMonth: dayjs().format("YYYYMM"),
      },
    };

    const response: any = await API.graphql(
      graphqlOperation(createSelfDiagnosisContent, variables)
    );

    return response.data.createSelfDiagnosisContent;
  }

  async update(input: UpdateSelfDiagnosisContentInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateSelfDiagnosisContent, {
        input,
      })
    );
  }
}
