import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface CommonState {
  currentShopId?: string;
  consultationTermsId?: number;
  showConsultationTermsModal: boolean;
  userGroups: string[];
}

const initialState: CommonState = {
  currentShopId: undefined,
  consultationTermsId: undefined,
  showConsultationTermsModal: false,
  userGroups: [],
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCurrentShopId: (state, action: PayloadAction<string>) => {
      state.currentShopId = action.payload;
    },
    setConsultationTermsId: (state, action: PayloadAction<number>) => {
      state.consultationTermsId = action.payload;
    },
    setShowConsultationTermsModal: (state, action: PayloadAction<boolean>) => {
      state.showConsultationTermsModal = action.payload;
    },
    setUserGroups: (state, action: PayloadAction<string[]>) => {
      state.userGroups = action.payload;
    },
  },
});

export const selectCurrentShopId = (state: RootState) =>
  state.common.currentShopId;

export const selectConsultationTermsId = (state: RootState) =>
  state.common.consultationTermsId;

export const selectShowConsultationTermsModal = (state: RootState) =>
  state.common.showConsultationTermsModal;

export const selectUserGroups = (state: RootState) => state.common.userGroups;

export const {
  setCurrentShopId,
  setConsultationTermsId,
  setShowConsultationTermsModal,
  setUserGroups,
} = commonSlice.actions;

export default commonSlice.reducer;
