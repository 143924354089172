import { Button } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import CategorySubTitle from "../CategorySubTitle";
import styles from "./index.module.scss";

export default function MultiSelectionFromCategorizedStringArray({
  constants,
  inputArray,
  inputFieldName,
  onClick,
}: {
  constants: { name: string; items: string[] }[];
  inputArray: string[];
  inputFieldName: string;
  onClick: any;
}) {
  return (
    <Row>
      {constants.map((category) => (
        <Col key={category.name} xs={12} className={styles.Box}>
          <Row>
            <Col>
              <CategorySubTitle title={category.name} />
            </Col>
          </Row>
          <Row>
            {category.items.map((item) => (
              <Col xs={4} key={item} className="d-grid">
                <Button
                  color="info"
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                  variant={
                    inputArray?.includes(item) ? "contained" : "outlined"
                  }
                  onClick={() => {
                    onClick(item, inputArray, inputFieldName);
                  }}
                >
                  {item}
                </Button>
              </Col>
            ))}
          </Row>
        </Col>
      ))}
    </Row>
  );
}
