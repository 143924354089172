import { Box, Button, Chip } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ConsultationAPI from "../../api/consultation";
import DesignerAPI from "../../api/designer";
import consultationConstants from "../../app/constants/consultation";
import { Consultation, Designer, Shop, Solution } from "../../graphql/API";
import styles from "./index.module.scss";
import { Col, Row } from "react-bootstrap";
import ShopAPI from "../../api/shop";

function Recommendation({ label }: { label: string }) {
  return (
    <Chip
      color="default"
      variant="outlined"
      style={{ marginRight: 5, marginBottom: 5 }}
      label={label}
    ></Chip>
  );
}

export default function SolutionSheet(props: any) {
  let { id } = useParams();

  const [consultation, setConsultation] = useState<Consultation>();
  const [solution, setSolution] = useState<Solution>();
  const [designer, setDesigner] = useState<Designer>();
  const [shop, setShop] = useState<Shop>();

  const consultationAPI = new ConsultationAPI();
  const designerAPI = new DesignerAPI();
  const shopAPI = new ShopAPI();

  useEffect(() => {
    if (!id) {
      return;
    }

    initialize(id);
  }, [id]);

  async function initialize(id: string) {
    const data = await consultationAPI.get(id);

    if (!data) {
      toast.warn("ID에 해당하는 컨설테이션이 존재하지 않습니다.");
      return;
    }

    setConsultation(data);
    setSolution(data.solution ?? undefined);

    if (data.designerId) {
      const designer = await designerAPI.get(data.designerId);

      if (designer) {
        setDesigner(designer);
      }
    }

    if (data.shopId) {
      const shop = await shopAPI.get(data.shopId);

      if (shop) {
        setShop(shop);
      }
    }
  }

  const faceShapes = (
    consultation?.faceShapes ?? [consultation?.faceShape] ??
    []
  ).filter((item) => item);

  const hasFaceShape = (faceShapes.length ?? 0) > 0;

  const dateAsDayjs = dayjs(consultation?.date);

  if (!consultation) {
    return <></>;
  }

  return (
    <div className={styles.SolutionContainer}>
      <div className={styles.SolutionArea}>
        <div className={styles.SolutionTitle}>
          {consultation.designerName}'s Solution
        </div>
        <div className={styles.SolutionContent}>
          <div className={styles.SolutionPreface}>
            안녕하세요! <code>{consultation.customer?.name}</code> 고객님.
            <br />
            {consultation.shopName} <strong>{consultation.designerName}</strong>
            입니다.
            <br />
            {dateAsDayjs.format("M")}월 {dateAsDayjs.format("DD")}일 결과지를
            다음과 같이 보내드립니다.
          </div>
          <div className={styles.SolutionQuestionFirstGroup}>
            {hasFaceShape && (
              <>
                <div className={styles.SolutionQuestionFirstGroupTitle}>
                  <strong>얼굴형 특징</strong>
                  {hasFaceShape && (
                    <Row className={styles.FaceShapes}>
                      {faceShapes.map((faceShape) => {
                        const info = consultationConstants.faceShape.find(
                          (item) => item.name === faceShape
                        );
                        if (!info) {
                          return "";
                        }
                        return (
                          <Col key={faceShape}>
                            <img
                              src={
                                "/images/consultation/face-shape/" +
                                info.imageFilePath +
                                ".png"
                              }
                              alt={info.name}
                            />
                            <div className={styles.Title}>{info.title}</div>
                            <span>{info.description}</span>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                  {solution?.face && (
                    <div style={{ fontSize: 13 }}>(참고) {solution.face}</div>
                  )}
                </div>
              </>
            )}
            {(consultation?.frontHeadShape || consultation?.sideHeadShape) && (
              <div className={styles.SolutionQuestionFirstGroupTitle}>
                <strong>두상 특징</strong>
                <Row className={styles.HeadShapes}>
                  {consultation.frontHeadShape && (
                    <Col className={styles.FrontHeadShape}>
                      <div>
                        <img
                          src={`../../images/consultation/front-head-shape/${
                            consultationConstants.frontHeadShape.find(
                              (item) =>
                                item.name === consultation.frontHeadShape
                            )?.imageFilePath
                          }.png`}
                        />
                      </div>
                      <span>
                        {
                          consultationConstants.frontHeadShape.find(
                            (item) => item.name === consultation.frontHeadShape
                          )?.description
                        }
                      </span>
                    </Col>
                  )}
                  {consultation.sideHeadShape && (
                    <Col className={styles.SideHeadShape}>
                      <div>
                        <img
                          src={`../../images/consultation/side-head-shape/${
                            consultationConstants.sideHeadShape.find(
                              (item) => item.name === consultation.sideHeadShape
                            )?.imageFilePath
                          }.png`}
                        />
                      </div>
                      <span>
                        {
                          consultationConstants.sideHeadShape.find(
                            (item) => item.name === consultation.sideHeadShape
                          )?.description
                        }
                      </span>
                    </Col>
                  )}
                </Row>
              </div>
            )}
            {(consultation.damageDegreeOfHair || solution?.hair) && (
              <>
                <div className={styles.SolutionQuestionFirstGroupTitle}>
                  <strong>모발 상태</strong>
                  <div className={styles.HairStatusChips}>
                    {consultation.damageDegreeOfHair && (
                      <Recommendation
                        label={`손상도: ${consultation.damageDegreeOfHair}`}
                      />
                    )}
                    {consultation.amountOfHair && (
                      <Recommendation
                        label={`모량: ${consultation.amountOfHair}`}
                      />
                    )}
                    {consultation.curlDegreeOfHair && (
                      <Recommendation
                        label={`모질: ${consultation.curlDegreeOfHair}`}
                      />
                    )}
                    {consultation.thicknessOfHair && (
                      <Recommendation
                        label={`모발 굵기: ${consultation.thicknessOfHair}`}
                      />
                    )}
                  </div>
                  {solution?.hair && <div>{solution.hair}</div>}
                </div>
              </>
            )}
            {solution?.needs && (
              <div className={styles.SolutionQuestionFirstGroupTitle}>
                <strong>니즈</strong>
                <div>{(solution && solution.needs) || ""}</div>
              </div>
            )}
          </div>
          <div className={styles.SolutaionQuestionLastGroup}>
            {solution && solution.point && (
              <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                <strong>오늘 헤어 시술 포인트</strong>
                <div style={{ whiteSpace: "pre-line" }}>
                  {solution.point || ""}
                </div>
              </div>
            )}
            {solution && solution.aftercareTips && (
              <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                <strong>관리 방법</strong>
                <div style={{ whiteSpace: "pre-line" }}>
                  {solution.aftercareTips || ""}
                </div>
              </div>
            )}
            {solution && solution.stylingTips && (
              <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                <strong>스타일링 방법</strong>
                <div style={{ whiteSpace: "pre-line" }}>
                  {solution.stylingTips || ""}
                </div>
              </div>
            )}
            {solution && solution.precautions && (
              <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                <strong>주의사항</strong>
                <div style={{ whiteSpace: "pre-line" }}>
                  {solution.precautions || ""}
                </div>
              </div>
            )}
            <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
              <strong>추천 시술</strong>
              <div className={styles.HairRecommendationChips}>
                {consultation?.hairRecommendation?.bangs && (
                  <Recommendation
                    label={`앞머리: ${consultation?.hairRecommendation?.bangs}`}
                  />
                )}
                {consultation?.hairRecommendation?.hairLength && (
                  <Recommendation
                    label={`길이: ${consultation?.hairRecommendation?.hairLength}`}
                  />
                )}
                {!consultation?.hairRecommendation?.hairVolumes &&
                  consultation?.hairRecommendation?.hairVolume && (
                    <Recommendation
                      label={`볼륨: ${consultation?.hairRecommendation?.hairVolume}`}
                    />
                  )}
                {!consultation?.hairRecommendation?.haircuts &&
                  consultation?.hairRecommendation?.haircut && (
                    <Recommendation
                      label={`커트: ${consultation?.hairRecommendation?.haircut}`}
                    />
                  )}
                {(consultation?.hairRecommendation?.hairVolumes?.length ?? 0) >
                  0 && (
                  <Recommendation
                    label={`볼륨: ${consultation?.hairRecommendation?.hairVolumes?.join(
                      ", "
                    )}`}
                  />
                )}
                {(consultation?.hairRecommendation?.haircuts?.length ?? 0) >
                  0 && (
                  <Recommendation
                    label={`커트: ${consultation?.hairRecommendation?.haircuts?.join(
                      ", "
                    )}`}
                  />
                )}
                {consultation?.hairRecommendation?.hairColorMenu && (
                  <Recommendation
                    label={`컬러 메뉴: ${consultation?.hairRecommendation?.hairColorMenu}`}
                  />
                )}
                {consultation?.hairRecommendation?.hairColorType && (
                  <Chip
                    label={`컬러 타입: ${consultation?.hairRecommendation?.hairColorType}`}
                    style={{
                      backgroundColor: (
                        consultationConstants.hairRecommendation
                          .hairColorTypeHash as any
                      )[consultation?.hairRecommendation?.hairColorType],
                      marginRight: 5,
                      marginBottom: 5,
                      color: "white",
                    }}
                  />
                )}
                {consultation?.hairRecommendation?.hairFirm && (
                  <Recommendation
                    label={`펌: ${consultation?.hairRecommendation?.hairFirm}`}
                  />
                )}
                {consultation?.hairRecommendation?.hairCare && (
                  <Recommendation
                    label={`케어: ${consultation?.hairRecommendation?.hairCare}`}
                  />
                )}
              </div>
              {solution && solution.recommendedTreatment && (
                <div style={{ whiteSpace: "pre-line" }}>
                  {solution.recommendedTreatment || ""}
                </div>
              )}
            </div>
            {solution?.link && (
              <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                <strong>참고 링크</strong>
                <div>
                  <a href={solution.link} target="_blank">
                    {solution.link}
                  </a>
                </div>
              </div>
            )}
            {solution?.nextVisitDate && (
              <div className={styles.SolutaionQuestionLastGroupQuestionBox}>
                <strong>다음 방문 일자</strong>
                <div>{solution.nextVisitDate}</div>
                {shop?.naverBusinessId && designer?.bizItemId && (
                  <Box sx={{ marginTop: 1 }}>
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      onClick={() => {
                        window.open(
                          `https://booking.naver.com/booking/13/bizes/${shop.naverBusinessId}/items/${designer.bizItemId}`
                        );
                      }}
                    >
                      지금 네이버 예약하기
                    </Button>
                  </Box>
                )}
              </div>
            )}
          </div>
          <div className={styles.Goodbye}>
            다음 예약에 뵙겠습니다. 감사합니다.
            <br />
            <strong>{consultation.designerName}</strong> 드림.
            {(designer?.instagramUrl || designer?.youtubeUrl) && (
              <div className={styles.SNS}>
                {designer?.instagramUrl && (
                  <a
                    href={designer?.instagramUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon fontSize="large" color="info" />
                  </a>
                )}
                {designer?.youtubeUrl && (
                  <a
                    href={designer?.youtubeUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YouTubeIcon fontSize="large" color="info" />
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
