import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import {
  Booking,
  BookingByCustomerIdQueryVariables,
  BookingStatus,
} from "../../graphql/API";

export default class BookingAPI {
  getBooking = /* GraphQL */ `
    query GetBooking($id: ID!) {
      getBooking(id: $id) {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  `;

  bookingByCustomerId = /* GraphQL */ `
    query BookingByCustomerId(
      $customerId: ID!
      $date: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelBookingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      bookingByCustomerId(
        customerId: $customerId
        date: $date
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          yearMonth
          date
          time
          customer {
            name
            id
          }
          customerId
          name
          phoneNumber
          consultationId
          consultation {
            status
            selfDiagnosisStatus
            solutionSentAt
            inflowChannel
            hasPhotos
          }
          hasSelfDiagnosis
          hasRegularCustomerNote
          designerName
          designerId
          optionName
          shopId
          shopName
          naverBookingNumber
          bookingStatus
          serviceStatus
          paymentStatus
          requestMessage
          designerMemo
          initialPrice
          finalPrice
          totalServiceSales
          totalProductSales
          initialDeposit
          paidAmount
          paidInCashAmount
          paidByCardAmount
          paidByPrepaidPassAmount
          paidByNaverPayAmount
          paidAt
          paymentMemo
          checkInRequests
          drink
          checkedInAt
          origin
          options {
            name
          }
          selfDiagnosisSentAt
          feedbackRequestSentAt
          hasFeedback
          hasPhotos
          photos {
            s3Key
            displayOrder
          }
          hasDesignCompletionPhotos
          designCompletionPhotos {
            s3Key
            displayOrder
          }
          signatureOfPortraitRights
          signedAtOfPortraitRights
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  `;

  async get(id: string): Promise<Booking> {
    const response: any = await API.graphql(
      graphqlOperation(this.getBooking, {
        id,
      })
    );

    return response.data.getBooking;
  }
  async listByCustomerId(
    customerId: string,
    bookingId = ""
  ): Promise<Booking[]> {
    const variables: BookingByCustomerIdQueryVariables = {
      customerId,
      date: {
        lt: dayjs().add(1, "day").format("YYYY-MM-DD"),
      },
      limit: 2,
      filter: {
        id: {
          ne: bookingId,
        },
        bookingStatus: {
          ne: BookingStatus.CANCELED,
        },
      },
    };

    const result: any = await API.graphql(
      graphqlOperation(this.bookingByCustomerId, variables)
    );

    return result.data.bookingByCustomerId.items;
  }
}
