import { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import consultationConstants from "../../../../app/constants/consultation";
import { ConsultationGender } from "../../../../models";
import MultiSelectionFromCategorizedStringArray from "../MultiSelectionFromCategorizedStringArray";
import MultiSelectionFromStringArray from "../MultiSelectionFromStringArray";
import QuestionTitle from "../QuestionTitle";
import styles from "./index.module.scss";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import {
  AnonymousPhoto,
  CreateSelfDiagnosisContentInput,
  CreateSelfDiagnosisInput,
} from "../../../../graphql/API";
import AnonymousPhotoAPI from "../../../../api/anonymous-photo";
import SingleSelectionFromStringArray from "../SingleSelectionFromStringArray";
import DeleteIcon from "./DeleteIcon";
import PageHeader from "../../../Shared/PageHeader";
import { Box, Button, MobileStepper } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  setConsultationTermsId,
  setShowConsultationTermsModal,
} from "../../../../features/common";

export default function StepOne({
  bookingId,
  requestId,
  input,
  setInput,
  toggleSelection,
  completed,
  photos,
  fetchPhotos,
  processing,
  handleSaveChanges,
}: {
  bookingId?: string;
  requestId?: string;
  input: CreateSelfDiagnosisInput & CreateSelfDiagnosisContentInput;
  setInput: any;
  toggleSelection: any;
  completed: boolean;
  photos: AnonymousPhoto[];
  fetchPhotos: () => void;
  processing: boolean;
  handleSaveChanges: () => void;
}) {
  const dispatch = useDispatch();

  const [uploading, setUploading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const anonymousPhotoAPI = new AnonymousPhotoAPI();

  function onClickShowConsultationTermsModal(index: number) {
    dispatch(setConsultationTermsId(index));
    dispatch(setShowConsultationTermsModal(true));
  }

  async function onFileInputChange(event: any) {
    setUploading(true);

    const targetLength = event?.target?.files.length ?? 0;

    if (photos.length + targetLength > 3) {
      toast.warn("사진은 최대 3장까지만 등록 가능합니다.");
      setUploading(false);
      return;
    }

    try {
      let files = [];
      for (var i = 0; i < targetLength; i++) {
        files.push(event.target.files.item(i));
      }
      await Promise.all(
        files.map((f, index) =>
          anonymousPhotoAPI.uploadFile(
            f,
            {
              displayOrder: photos.length + index,
              bookingId,
              requestId,
            },
            fetchPhotos
          )
        )
      );
    } catch (error: any) {
      toast.warn(error.message);
    } finally {
      setUploading(false);
    }
  }

  async function onDeletePhotoClick(photo: AnonymousPhoto) {
    await anonymousPhotoAPI.delete(photo.id, photo._version);

    fetchPhotos();
  }

  let maxSteps = 16;

  async function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  async function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  return (
    <>
      <Box
        sx={{
          height: "86vh",
          padding: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <PageHeader
            title={"셀프 진단"}
            displayAgreements={activeStep === 0}
            forMOMI={!!requestId}
          />
          {activeStep === 0 && (
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {completed ? (
                  <Box
                    sx={{
                      alignSelf: "center",
                      textDecorationLine: "underline",
                    }}
                  >
                    제출 완료하셨습니다
                  </Box>
                ) : (
                  <Button
                    onClick={handleNext}
                    sx={{ width: "150px", alignSelf: "center" }}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    시작
                  </Button>
                )}
              </Box>
            </>
          )}
          {activeStep === 1 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle title={"고객님의 성별을 선택해주세요."} />
              <Box className={styles.ButtonBox}>
                <Row>
                  <Col xs={4} className="d-grid">
                    <Button
                      variant={
                        input.gender === ConsultationGender.MALE
                          ? "contained"
                          : "outlined"
                      }
                      color="info"
                      onClick={(event) => {
                        setInput({
                          ...input,
                          gender: ConsultationGender.MALE,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      남성
                    </Button>
                  </Col>
                  <Col xs={4} className="d-grid">
                    <Button
                      variant={
                        input.gender === ConsultationGender.FEMALE
                          ? "contained"
                          : "outlined"
                      }
                      color="info"
                      onClick={(event) => {
                        setInput({
                          ...input,
                          gender: ConsultationGender.FEMALE,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      여성
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Form.Group>
          )}
          {activeStep === 2 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"고객님이 방문하시게 된 최초 경로를 선택해주세요."}
              />
              <Box className={styles.ButtonBox}>
                <SingleSelectionFromStringArray
                  constants={consultationConstants.inflowChannel}
                  inputValue={input.inflowChannel ?? ""}
                  inputFieldName="inflowChannel"
                  onClick={toggleSelection}
                  xs={4}
                />
              </Box>
            </Form.Group>
          )}
          {activeStep === 3 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"샴푸 하실 때 사용하시는 제품을 알려주세요."}
                multiSelection={true}
              />
              <Box className={styles.ButtonBox}>
                <MultiSelectionFromStringArray
                  constants={consultationConstants.productsForShampooing}
                  inputArray={input.productsForShampooing ?? []}
                  inputFieldName="productsForShampooing"
                  onClick={toggleSelection}
                />
              </Box>
            </Form.Group>
          )}
          {activeStep === 4 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle title={"드라이 및 세팅을 하시나요?"} />
              <Box className={styles.ButtonBox}>
                <Row>
                  <Col xs={4} className="d-grid">
                    <Button
                      color="info"
                      variant={input.dry === true ? "contained" : "outlined"}
                      onClick={(event) => {
                        setInput({
                          ...input,
                          dry: true,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      예
                    </Button>
                  </Col>
                  <Col xs={4} className="d-grid">
                    <Button
                      color="info"
                      variant={input.dry === false ? "contained" : "outlined"}
                      onClick={(event) => {
                        setInput({
                          ...input,
                          dry: false,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      아니오
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Form.Group>
          )}
          {activeStep === 5 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"스타일링 하실 때 사용하시는 제품을 알려주세요."}
                multiSelection={true}
              />
              <Box className={styles.ButtonBox}>
                <MultiSelectionFromStringArray
                  constants={consultationConstants.productsForStyling}
                  inputArray={input.productsForStyling ?? []}
                  inputFieldName="productsForStyling"
                  onClick={toggleSelection}
                  xs={4}
                />
              </Box>
            </Form.Group>
          )}
          {activeStep === 6 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"관심을 가지고 계신 헤어 시술의 종류를 알려주세요."}
                multiSelection={true}
              />
              <Box className={styles.ButtonBox}>
                <MultiSelectionFromCategorizedStringArray
                  constants={consultationConstants.treatmentsInterestedIn}
                  inputArray={input.treatmentsInterestedIn ?? []}
                  inputFieldName="treatmentsInterestedIn"
                  onClick={toggleSelection}
                />
              </Box>
            </Form.Group>
          )}
          {activeStep === 7 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle title={"미용실 방문 주기를 알려주세요."} />
              <Box className={styles.ButtonBox}>
                <MultiSelectionFromCategorizedStringArray
                  constants={consultationConstants.frequencyOfVisits}
                  inputArray={[input.frequencyOfVisits ?? ""]}
                  inputFieldName="frequencyOfVisits"
                  onClick={toggleSelection}
                />
              </Box>
            </Form.Group>
          )}
          {activeStep === 8 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"고객님의 모발 및 두피 고민은 무엇인가요?"}
                multiSelection={true}
              />
              <Box className={styles.ButtonBox}>
                <MultiSelectionFromCategorizedStringArray
                  constants={consultationConstants.concerns}
                  inputArray={input.concerns ?? []}
                  inputFieldName="concerns"
                  onClick={toggleSelection}
                />
              </Box>
            </Form.Group>
          )}
          {activeStep === 9 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"평소 즐겨입는 스타일을 알려주세요."}
                multiSelection={true}
              />
              <Box className={styles.ButtonBox}>
                <MultiSelectionFromStringArray
                  constants={consultationConstants.outfitStyles}
                  inputArray={input.outfitStyles ?? []}
                  inputFieldName="outfitStyles"
                  onClick={toggleSelection}
                  xs={4}
                />
              </Box>
            </Form.Group>
          )}
          {activeStep === 10 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle title="최근 염색 시기(해당 시)" />
              <Box className={styles.ButtonBox}>
                <SingleSelectionFromStringArray
                  constants={consultationConstants.recentDyeing}
                  inputValue={input.recentDyeing ?? ""}
                  inputFieldName="recentDyeing"
                  onClick={toggleSelection}
                  xs={4}
                />
              </Box>
            </Form.Group>
          )}
          {activeStep === 11 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle
                title={"블랙 염색 상태인가요?(컬러 염색이 어려워요)"}
              />
              <Box className={styles.ButtonBox}>
                <Row>
                  <Col xs={4} className="d-grid">
                    <Button
                      color="info"
                      variant={
                        input.blackDyedHair === true ? "contained" : "outlined"
                      }
                      onClick={(event) => {
                        setInput({
                          ...input,
                          blackDyedHair: true,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      예
                    </Button>
                  </Col>
                  <Col xs={4} className="d-grid">
                    <Button
                      color="info"
                      variant={
                        input.blackDyedHair === false ? "contained" : "outlined"
                      }
                      onClick={(event) => {
                        setInput({
                          ...input,
                          blackDyedHair: false,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      아니오
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Form.Group>
          )}
          {activeStep === 12 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle title={"탈색 상태인가요?(펌 시술이 어려워요)"} />
              <Box className={styles.ButtonBox}>
                <Row>
                  <Col xs={4} className="d-grid">
                    <Button
                      color="info"
                      variant={
                        input.bleachedHair === true ? "contained" : "outlined"
                      }
                      onClick={(event) => {
                        setInput({
                          ...input,
                          bleachedHair: true,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      예
                    </Button>
                  </Col>
                  <Col xs={4} className="d-grid">
                    <Button
                      color="info"
                      variant={
                        input.bleachedHair === false ? "contained" : "outlined"
                      }
                      onClick={(event) => {
                        setInput({
                          ...input,
                          bleachedHair: false,
                        });
                        event.currentTarget.blur();
                      }}
                    >
                      아니오
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Form.Group>
          )}
          {activeStep === 13 && (
            <Form.Group className={styles.QuestionBox}>
              <QuestionTitle title="최근 펌 시기(해당 시)" />
              <Box className={styles.ButtonBox}>
                <SingleSelectionFromStringArray
                  constants={consultationConstants.recentPerm}
                  inputValue={input.recentPerm ?? ""}
                  inputFieldName="recentPerm"
                  onClick={toggleSelection}
                  xs={4}
                />
              </Box>
            </Form.Group>
          )}
          {activeStep === 14 && (
            <Form.Group
              className={styles.QuestionBox}
              controlId="modalForm.photo"
            >
              <QuestionTitle
                title={"원하는 스타일이 있으시다면 첨부해주세요."}
                multiSelection={false}
                isLastQuestion={true}
              />

              {photos.length == 0 && (
                <Row className={styles.FirstPictureBox}>
                  <Col>
                    <Button
                      startIcon={<PhotoCameraIcon />}
                      variant="outlined"
                      color="info"
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById("add-image-file-input")
                          ?.click();
                      }}
                      disabled={uploading || completed}
                    >
                      사진 첨부
                    </Button>
                    <input
                      id="add-image-file-input"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={onFileInputChange}
                      style={{ display: "none" }}
                    />
                  </Col>
                </Row>
              )}
              {photos.length > 0 && (
                <Row className={styles.PictureListBox}>
                  <>
                    {photos.map((photo) => {
                      return (
                        <Col key={photo.id} className={styles.PictureBox}>
                          <img src={(photo as any).url}></img>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              onDeletePhotoClick(photo);
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </Col>
                      );
                    })}
                    {photos.length < 3 && (
                      <div className={styles.PictureBox}>
                        <button
                          className={styles.PictureAddButton}
                          onClick={(e) => {
                            e.preventDefault();
                            document
                              .getElementById("add-image-file-input")
                              ?.click();
                          }}
                          disabled={uploading || completed}
                        >
                          <img
                            src="/static/images/icon/plus-icon.png"
                            alt={"더하기 아이콘"}
                          />
                        </button>
                        <input
                          id="add-image-file-input"
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={onFileInputChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </>
                </Row>
              )}
            </Form.Group>
          )}
          {activeStep === 15 && (
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              {completed ? (
                <Button
                  sx={{ width: "150px", alignSelf: "center" }}
                  variant="contained"
                  disabled
                >
                  제출완료
                </Button>
              ) : (
                <Box sx={{ alignSelf: "center" }}>
                  <Box sx={{ marginBottom: 4, typography: "h6" }}>
                    이제 모두 입력했어요!
                    <br />
                    감사합니다.
                  </Box>
                  <Button
                    sx={{ width: "150px" }}
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={processing}
                    onClick={handleSaveChanges}
                  >
                    {processing ? "제출중... 🏊🏻" : "제출하기"}
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              다음
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              이전
            </Button>
          }
        />
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            width: 200,
            margin: "0 auto",
          }}
        >
          <a
            onClick={() => onClickShowConsultationTermsModal(0)}
            style={{ textDecoration: "underline" }}
          >
            서비스 이용 약관
          </a>
          <a
            onClick={() => onClickShowConsultationTermsModal(2)}
            style={{ textDecoration: "underline" }}
          >
            개인정보 처리방침
          </a>
        </Box>
      </Box>
    </>
  );
}
