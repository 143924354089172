import { Form } from "react-bootstrap";
import styles from "./index.module.scss";

function MultiSelectionIsPossibleNotice() {
  return (
    <div
      style={{
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "14px",
        marginTop: "10px",
        textAlign: "start",
      }}
    >
      복수 선택 가능
    </div>
  );
}

function SingleSelectionIsPossibleNotice() {
  return (
    <div
      style={{
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "14px",
        marginTop: "10px",
        textAlign: "start",
      }}
    >
      하나만 선택
    </div>
  );
}

function PictureMaxCountNotice() {
  return (
    <div
      style={{
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "14px",
        marginTop: "10px",
        textAlign: "start",
      }}
    >
      최대 3장까지 첨부가능
    </div>
  );
}

export default function QuestionTitle({
  title,
  multiSelection,
  isLastQuestion,
  textInput = false,
}: {
  title: string;
  multiSelection?: boolean;
  isLastQuestion?: boolean;
  textInput?: boolean;
}) {
  return (
    <>
      <div>
        <div className={styles.QuestionBox}>
          <span className={styles.QuestionTitle}>Q. {title}</span>
        </div>
        {isLastQuestion ? <PictureMaxCountNotice /> : null}
        {textInput ? null : multiSelection ? (
          <MultiSelectionIsPossibleNotice />
        ) : (
          <SingleSelectionIsPossibleNotice />
        )}
      </div>
    </>
  );
}
