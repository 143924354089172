import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Storage } from "aws-amplify";
import BookingAPI from "../../api/booking";
import {
  AnonymousPhoto,
  Booking,
  ConsultationGender,
  CreateSelfDiagnosisInput,
  NestedPhoto,
} from "../../graphql/API";
import StepOne from "./Shared/StepOne";
import consultationConstants from "../../app/constants/consultation";
import ShopAPI from "../../api/shop";
import { toast } from "react-toastify";
import AnonymousPhotoAPI from "../../api/anonymous-photo";
import GA from "../../api/ga";
import SelfDiagnosisAPI from "../../api/self-diagnosis";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

export default function SubmitSelfDiagnosis() {
  let { id } = useParams();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const bookingAPI = new BookingAPI();
  const selfDiagnosisAPI = new SelfDiagnosisAPI();
  const shopAPI = new ShopAPI();
  const anonymousPhotoAPI = new AnonymousPhotoAPI();

  const [booking, setBooking] = useState<Booking>();
  const [processing, setProcessing] = useState(false);

  const defaultInput: CreateSelfDiagnosisInput = {
    gender: ConsultationGender.FEMALE,
    customerId: "",
    customerName: "",
    shopId: booking?.shopId ?? "",
    shopName: "",
    bookingId: "",
    yearMonth: "",
    date: "",
    designerId: "",
    designerName: "",
  };

  const [input, setInput] = useState<CreateSelfDiagnosisInput>(defaultInput);
  const [photos, setPhotos] = useState<AnonymousPhoto[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }

    initialize(id);
  }, [id]);

  async function initialize(id: string) {
    const booking = await bookingAPI.get(id);

    if (!booking) {
      toast.warn("예약 정보를 찾을 수 없습니다.");
      return;
    }

    setBooking(booking);

    const bookings = await bookingAPI.listByCustomerId(
      booking.customerId,
      booking.id
    );

    if (bookings.length > 0) {
      // 재방문
      navigate("/submit-regular-customer-note/" + booking.id);
      return;
    }

    fetchPhotos();
  }

  async function fetchPhotos() {
    if (!id) {
      return;
    }
    const photos = [];

    const items = await anonymousPhotoAPI.listByBookingId(id);

    for (let i = 0; i < items.length; i += 1) {
      const item = {
        ...items[i],
      };

      const signedURL = await Storage.get(item.s3Key);

      item.url = signedURL;

      photos.push(item);
    }

    setPhotos(photos);
  }

  function toggleSelection(
    selected: string,
    inputArray: any,
    inputFieldName: string
  ) {
    if (!input) {
      return;
    }

    if (consultationConstants.singleValueFieldNames.includes(inputFieldName)) {
      setInput({
        ...input,
        [inputFieldName]: selected,
      });
      return;
    }

    const existingIndex =
      inputArray?.findIndex((item: any) => item === selected) ?? -1;

    const existingArray = [...(inputArray ?? [])];

    if (existingIndex > -1) {
      existingArray.splice(existingIndex, 1);
    } else {
      existingArray.push(selected);
    }
    setInput({
      ...input,
      [inputFieldName]: existingArray,
    });
  }

  async function handleSaveChanges() {
    if (!id) {
      return;
    }

    await initialize(id);

    if (booking?.hasSelfDiagnosis) {
      toast.info("이미 완료하셨습니다.");
      return;
    }

    const { productsForShampooing, frequencyOfVisits } = input;

    if ((productsForShampooing ?? []).length < 1) {
      snackbar.enqueueSnackbar("샴푸 시 사용하시는 제품을 알려주세요.", {
        variant: "warning",
      });
      return;
    }

    if (!frequencyOfVisits) {
      snackbar.enqueueSnackbar("방문 빈도를 선택해주세요.", {
        variant: "warning",
      });
      return;
    }

    setProcessing(true);

    try {
      const booking = await bookingAPI.get(id);

      const hasPhotos = photos.length > 0;

      const nestedPhotos: NestedPhoto[] = photos.map((photo) => {
        return {
          s3Key: photo.s3Key,
          displayOrder: photo.displayOrder,
        } as any;
      });

      const { customer, designerName, designerId, shopId } = booking;

      if (!customer) {
        throw new Error("Customer is required");
      }

      const shop = await shopAPI.get(shopId);

      const shopName = shop.name;

      await selfDiagnosisAPI.create({
        ...input,
        customerId: customer.id!,
        customerName: customer.name!,
        shopId,
        shopName,
        bookingId: booking.id,
        designerName: designerName!,
        designerId: designerId!,
        hasPhotos,
        photos: nestedPhotos,
      });

      snackbar.enqueueSnackbar("셀프 진단을 완료했습니다.", {
        variant: "success",
      });

      GA.event("셀프 진단", "셀프 진단 등록", shopName);

      navigate("/");
    } catch (error: any) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error(JSON.stringify(error));
      }
    } finally {
      setProcessing(false);
    }

    await initialize(id);
  }

  if (!booking) {
    return <div>Loading...</div>;
  }

  const completed = !!booking?.hasSelfDiagnosis;

  return (
    <>
      <Form>
        <StepOne
          bookingId={id ?? ""}
          input={input as any}
          setInput={setInput}
          toggleSelection={toggleSelection}
          completed={completed}
          photos={photos}
          fetchPhotos={fetchPhotos}
          processing={processing}
          handleSaveChanges={handleSaveChanges}
        />
      </Form>
    </>
  );
}
