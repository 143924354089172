import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Storage } from "aws-amplify";
import {
  AnonymousPhoto,
  ConsultationGender,
  CreateSelfDiagnosisContentInput,
  NestedPhoto,
  SelfDiagnosisRequest,
} from "../../graphql/API";
import consultationConstants from "../../app/constants/consultation";
import { toast } from "react-toastify";
import AnonymousPhotoAPI from "../../api/anonymous-photo";
import GA from "../../api/ga";
import StepOne from "../SubmitSelfDiagnosis/Shared/StepOne";
import SelfDiagnosisContentAPI from "../../api/self-diagnosis-content";
import SelfDiagnosisRequestAPI from "../../api/self-diagnosis-request";
import { useSnackbar } from "notistack";

export default function SubmitSelfDiagnosisContent() {
  let { id } = useParams();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const selfDiagnosisRequestAPI = new SelfDiagnosisRequestAPI();
  const selfDiagnosisContentAPI = new SelfDiagnosisContentAPI();
  const anonymousPhotoAPI = new AnonymousPhotoAPI();

  const [request, setRequest] = useState<SelfDiagnosisRequest>();
  const [processing, setProcessing] = useState(false);

  const defaultInput: CreateSelfDiagnosisContentInput = {
    requestId: id!,
    gender: ConsultationGender.FEMALE,
    name: "",
    shopName: "",
    yearMonth: "",
    designerName: "",
  };

  const [input, setInput] =
    useState<CreateSelfDiagnosisContentInput>(defaultInput);
  const [photos, setPhotos] = useState<AnonymousPhoto[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }

    initialize(id);
  }, [id]);

  async function initialize(id: string) {
    const request = await selfDiagnosisRequestAPI.get(id);

    if (!request) {
      toast.warn("정보를 찾을 수 없습니다.");
      return;
    }

    setRequest(request);

    fetchPhotos();
  }

  async function fetchPhotos() {
    if (!id) {
      return;
    }
    const photos = [];

    const items = await anonymousPhotoAPI.listByRequestId(id);

    for (let i = 0; i < items.length; i += 1) {
      const item = {
        ...items[i],
      };

      const signedURL = await Storage.get(item.s3Key);

      item.url = signedURL;

      photos.push(item);
    }

    setPhotos(photos);
  }

  function toggleSelection(
    selected: string,
    inputArray: any,
    inputFieldName: string
  ) {
    if (!input || request?.writtenAt) {
      return;
    }

    if (consultationConstants.singleValueFieldNames.includes(inputFieldName)) {
      setInput({
        ...input,
        [inputFieldName]: selected,
      });
      return;
    }

    const existingIndex =
      inputArray?.findIndex((item: any) => item === selected) ?? -1;

    const existingArray = [...(inputArray ?? [])];

    if (existingIndex > -1) {
      existingArray.splice(existingIndex, 1);
    } else {
      existingArray.push(selected);
    }
    setInput({
      ...input,
      [inputFieldName]: existingArray,
    });
  }

  async function handleSaveChanges() {
    if (!id) {
      return;
    }

    if (!request) {
      return;
    }

    await initialize(id);

    if (request?.writtenAt) {
      toast.info("이미 완료하셨습니다.");
      return;
    }

    const { productsForShampooing, frequencyOfVisits } = input;

    if ((productsForShampooing ?? []).length < 1) {
      snackbar.enqueueSnackbar("샴푸 시 사용하시는 제품을 알려주세요.", {
        variant: "warning",
      });
      return;
    }

    if (!frequencyOfVisits) {
      snackbar.enqueueSnackbar("방문 빈도를 선택해주세요.", {
        variant: "warning",
      });
      return;
    }

    setProcessing(true);

    try {
      const hasPhotos = photos.length > 0;

      const nestedPhotos: NestedPhoto[] = photos.map((photo) => {
        return {
          s3Key: photo.s3Key,
          displayOrder: photo.displayOrder,
        } as any;
      });

      const { name, designerName, shopName } = request;

      await selfDiagnosisContentAPI.create({
        ...input,
        name,
        shopName,
        designerName: designerName!,
        hasPhotos,
        photos: nestedPhotos,
      });

      snackbar.enqueueSnackbar("셀프 진단을 완료했습니다.", {
        variant: "success",
      });

      GA.event("셀프 진단", "Native 셀프 진단 등록", shopName);

      navigate("/");
    } catch (error: any) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error(JSON.stringify(error));
      }
    } finally {
      setProcessing(false);
    }

    setTimeout(() => {
      initialize(id!);
    }, 2000);
  }

  if (!request) {
    return <>데이터를 확인하고 있습니다...</>;
  }

  const completed = !!request?.writtenAt;

  return (
    <>
      <Form>
        <StepOne
          requestId={id}
          input={input as any}
          setInput={setInput}
          toggleSelection={toggleSelection}
          completed={completed}
          photos={photos}
          fetchPhotos={fetchPhotos}
          processing={processing}
          handleSaveChanges={handleSaveChanges}
        />
      </Form>
    </>
  );
}
