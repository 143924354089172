import { Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import styles from "./index.module.scss";

export default function MultiSelectionFromStringArray({
  constants,
  inputArray,
  inputFieldName,
  onClick,
  xs,
}: {
  constants: string[];
  inputArray: string[];
  inputFieldName: string;
  onClick: any;
  xs?: number;
}) {
  return (
    <Row>
      {constants.map((item: any) => (
        <Col
          xs={xs ? xs : 6}
          key={item}
          className="d-grid"
          style={{ marginBottom: 10 }}
        >
          <Button
            color="info"
            variant={inputArray.includes(item) ? "contained" : "outlined"}
            onClick={() => {
              onClick(item, inputArray, inputFieldName);
            }}
          >
            {item.replace(/ /g, "")}
          </Button>
        </Col>
      ))}
    </Row>
  );
}
