import { Alert, Col, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { Designer } from "../../../graphql/API";

export default function DesignerIntroduction({
  designer,
}: {
  designer: Designer;
}) {
  return (
    <>
      <Row>
        <Col style={{}}>
          <Alert variant="light">
            <div style={{ marginBottom: 30 }}>
              <code>{designer.name}</code> 님의 소셜 계정에 방문해 보세요.
              <br />
              인기 스타일, 손쉬운 스타일링 노하우 등<br />
              다양한 정보를 만나실 수 있어요. 💇🏻‍♀️
            </div>
            <Row>
              <Col xs={4}></Col>
              {designer.instagramUrl && (
                <Col className="text-center">
                  <Icon.Instagram
                    size={30}
                    color="black"
                    onClick={() => {
                      window.open(designer.instagramUrl!, "_blank");
                    }}
                  />
                </Col>
              )}
              {designer.youtubeUrl && (
                <Col className="text-center">
                  <Icon.Youtube
                    size={30}
                    color="black"
                    onClick={() => {
                      window.open(designer.youtubeUrl!, "_blank");
                    }}
                  />
                </Col>
              )}
              <Col xs={4}></Col>
            </Row>
          </Alert>
        </Col>
      </Row>
    </>
  );
}
