import { API, graphqlOperation } from "aws-amplify";
import {
  Feedback,
  CreateFeedbackInput,
  UpdateFeedbackInput,
} from "../../graphql/API";
import { createFeedback, updateFeedback } from "../../graphql/mutations";
import { getFeedback } from "../../graphql/queries";

export default class FeedbackAPI {
  async get(bookingId: string): Promise<Feedback> {
    const response: any = await API.graphql(
      graphqlOperation(getFeedback, {
        bookingId,
      })
    );

    return response.data.getFeedback;
  }

  async create(input: CreateFeedbackInput): Promise<Feedback> {
    const response: any = await API.graphql(
      graphqlOperation(createFeedback, {
        input,
      })
    );

    return response.data.createFeedback;
  }

  async update(input: UpdateFeedbackInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateFeedback, {
        input,
      })
    );
  }
}
