import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import BookingAPI from "../../api/booking";
import DesignerAPI from "../../api/designer";
import FeedbackAPI from "../../api/feedback";
import ShopAPI from "../../api/shop";
import {
  Booking,
  CreateFeedbackInput,
  Designer,
  FeedbackCategory,
} from "../../graphql/API";
import Product from "../Shared/Product";
import GA from "../../api/ga";
import PageHeader from "../Shared/PageHeader";
import styles from "./index.module.scss";
import DesignerIntroduction from "../Shared/DesignerIntroduction";
import ProductV2 from "../Shared/ProductV2";

export default function SubmitFeedback() {
  let { id } = useParams();

  const navigate = useNavigate();

  const [booking, setBooking] = useState<Booking>();
  const [designer, setDesigner] = useState<Designer>();

  const defaultInput: CreateFeedbackInput = {
    bookingId: "",
    shopId: "",
    category: FeedbackCategory.SERVICE,
    shopName: "",
    designerName: "",
    designerId: "",
    date: "",
    satisfaction: 0,
    modelVersion: 1,
  };

  const [input, setInput] = useState<CreateFeedbackInput>(defaultInput);
  const [satisfactionText, setSatisfactionText] = useState<string>();
  const [sending, setSending] = useState<boolean>(false);

  const bookingAPI = new BookingAPI();
  const shopAPI = new ShopAPI();
  const feedbackAPI = new FeedbackAPI();
  const designerAPI = new DesignerAPI();
  const pageHeaderTitle = "피드백 수집";

  useEffect(() => {
    if (id) {
      initialize(id);
    }
  }, [id]);

  async function initialize(id: string) {
    const booking = await fetchBooking(id);

    if (!booking) {
      return;
    }

    const shop = await shopAPI.get(booking.shopId);

    const { id: bookingId, shopId, designerName, designerId } = booking;

    const date = dayjs().format("YYYY-MM-DD");

    setInput({
      ...input,
      bookingId,
      shopId,
      shopName: shop.name,
      designerName: designerName ?? "",
      designerId: designerId ?? "",
      date,
    });

    if (designerId) {
      const designer = await designerAPI.get(designerId);

      setDesigner(designer);
    }
  }

  async function fetchBooking(id: string) {
    const booking = await bookingAPI.get(id);

    if (!booking) {
      toast.error("예약 정보를 찾을 수 없습니다.");
      return;
    }

    setBooking(booking);

    return booking;
  }

  async function onSendFeedbackClick() {
    if (!booking) {
      return;
    }

    const feedback = await feedbackAPI.get(booking.id);

    if (feedback) {
      toast.info("이미 피드백을 작성하셨습니다.");
      return;
    }

    const { satisfaction } = input;

    if (!satisfaction) {
      toast.info("만족도를 선택해주세요.");
      return;
    }

    if (!confirm("이대로 전송할까요?")) {
      return;
    }

    setSending(true);

    try {
      const result = await feedbackAPI.create(input);

      if (result) {
        toast.success("피드백을 전송했습니다. 🙏🏻");

        GA.event("피드백", "피드백 등록", input.shopName);
      }
    } finally {
      setSending(false);

      fetchBooking(booking.id);
    }
  }

  if (!booking) {
    return <>데이터를 확인하고 있습니다...</>;
  }

  if (booking.hasFeedback) {
    return (
      <Container>
        <PageHeader title={pageHeaderTitle} />
        <Row>
          <Col>
            <p>
              <code>{booking.customer?.name}</code> 고객님.
              <br />
              소중한 시간을 내어 피드백을 작성해 주셔서
              <br />
              진심으로 감사합니다. 🙇🏻
            </p>
          </Col>
        </Row>
        {designer && (designer.instagramUrl || designer?.youtubeUrl) && (
          <DesignerIntroduction designer={designer} />
        )}
        <ProductV2 />
      </Container>
    );
  }

  return (
    <Container>
      <PageHeader title={pageHeaderTitle} />
      <Row>
        <Col className={styles.FeedbackBox}>
          <p>
            <code>{booking.customer?.name}</code> 고객님 안녕하세요!
            <br />
            이번 서비스는 충분히 만족스러우셨나요?
          </p>
          <p>앞으로 더 나은 서비스를 위해 피드백을 남겨주세요! 💕</p>

          <Table responsive>
            <thead>
              <tr>
                <th>일시</th>
                <th>디자이너</th>
                <th>서비스</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {booking.date}
                  <br />
                  {booking.time}
                </td>
                <td>{booking.designerName}</td>
                <td>
                  {booking.options?.map((option) => (
                    <div>{option.name}</div>
                  ))}
                </td>
              </tr>
            </tbody>
          </Table>
          <div>
            <Row>
              <Col className="d-grid">
                <Button
                  variant={`${
                    input.satisfaction === 1 ? "warning" : "outline-warning"
                  }`}
                  onClick={() => {
                    setInput({ ...input, satisfaction: 1 });
                    setSatisfactionText("아쉬움");
                  }}
                >
                  아쉬움
                </Button>
              </Col>
              <Col className="d-grid">
                <Button
                  variant={`${
                    input.satisfaction === 2 ? "primary" : "outline-primary"
                  }`}
                  onClick={() => {
                    setInput({ ...input, satisfaction: 2 });
                    setSatisfactionText("만족");
                  }}
                >
                  만족
                </Button>
              </Col>
              <Col className="d-grid">
                <Button
                  variant={`${
                    input.satisfaction === 3 ? "success" : "outline-success"
                  }`}
                  onClick={() => {
                    setInput({ ...input, satisfaction: 3 });
                    setSatisfactionText("매우 만족");
                  }}
                >
                  매우 만족
                </Button>
              </Col>
            </Row>
          </div>
          {satisfactionText && (
            <>
              <div style={{ textAlign: "initial", marginTop: 30 }}>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <code>{satisfactionText}</code>을 선택해 주신 이유를
                      알려주세요.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      onChange={(event) => {
                        setInput({ ...input, comment: event.target.value });
                      }}
                    />
                    <Form.Text className="text-muted">
                      [선택 입력] 오직 더 나은 서비스를 위한 참고 목적으로만
                      사용됩니다.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>응원 메시지</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      onChange={(event) => {
                        setInput({
                          ...input,
                          supportMessage: event.target.value,
                        });
                      }}
                    />
                    <Form.Text className="text-muted">
                      [선택 입력] 담당 디자이너에게 응원의 메시지를 남겨주세요.
                    </Form.Text>
                  </Form.Group>
                </Form>
              </div>

              <div>
                <Button
                  variant="dark"
                  disabled={sending}
                  onClick={onSendFeedbackClick}
                >
                  {sending ? "전송중... 🏄🏻" : "피드백 전송"}
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
