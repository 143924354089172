import { Col, Row } from "react-bootstrap";
import styles from "./index.module.scss";

type Props = {
  title: string;
  displayAgreements?: boolean;
  forMOMI?: boolean;
};

export default function PageHeader({
  title,
  displayAgreements,
  forMOMI = false,
}: Props) {
  return (
    <Row>
      <Col>
        <div className={styles.TopBrandMessage}>
          {forMOMI
            ? "모미 - 모든 미용인과 고객을 위한 앱"
            : "NaNalog - for all salons in the world"}
        </div>
        <div className={styles.HeaderDivider}></div>
        <div className={styles.Title}>{title}</div>
        {displayAgreements && (
          <>
            <div className={styles.SubTitle}>
              헤어 컨설테이션 맞춤형 서비스 제공 동의
            </div>
            <div className={styles.SubText}>
              수집한 정보는 원활한 서비스 제공을 위해 사용됩니다. 계속
              진행하시면 사이트 하단의 개인정보 수집 및 이용에 동의하신 것으로
              간주합니다.
              <br />
              약관 및 자세한 사항은 사이트 하단의 링크를 통해 확인하실 수
              있습니다.
            </div>
          </>
        )}
      </Col>
    </Row>
  );
}
